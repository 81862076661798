// Eventually add more than just tooltips :)
const texts = {
    "loading": "loading",

    // "tooltip_language_count": "The Hyperglot database is a work in progress. It currently includes orthographies for over % languages. Your font may support other languages not included in the database.",
    "tooltip_language_count_homepage": "The Hyperglot database is (and will continue to be) a work in progress. It currently includes orthographies for over 774 languages. Your font may support other languages not included in the database.",
    "tooltip_language_count": "The Hyperglot database is a work in progress. It currently includes orthographies for over 774 languages. Your font may support other languages not included in the database.",
    "tooltip_speaker_count_homepage": "The total number of speakers is based on L1 numbers from various sources as reported on Wikipedia. This simple sum does not account for multilingualism or literacy rates.",
    "tooltip_speaker_count": "The total number of speakers is based on first-language (L1) numbers from various sources as reported on Wikipedia. This simple sum does not account for multilingualism or literacy rates.",

    "tooltip_local_font": "Your fonts are not uploaded to our server. They are only used locally in your browser to render the supported characters.",

    "tooltip_filters_historical": "Historical languages are languages with no first-language (L1) speakers. Constructed languages (also called auxiliary languages) include Esperanto and Interlingue, for example.",
    "tooltip_filters_secondary": "Each language can have multiple orthographies. To detect language support, primary orthographies are used by default. Secondary orthographies are used less frequently. Historical orthographies are no longer in use.",
    "tooltip_filters_draft": "Show data that has not been sufficiently verified with authoritative sources (status: draft).",
    "tooltip_filters_verfied": "Show data that has been verified by at least two online sources (status: preliminary) or by a competent speaker or linguist (status: verified).",
    "tooltip_filters_details": "Indicate the level of validity of languages data and show links to full entries in the database.",

    // Tooltips on the character grid cells
    "tooltip_character_search": "Show languages that require this character in the left panel",
    "tooltip_character_info": "Show the design requirements below",

    // These are stored shorted to one their first letter
    "tooltip_validity_t": "This orthography has not been properly entered.",
    "tooltip_validity_d": "This orthography has been collected in the database but is lacking review.",
    "tooltip_validity_p": "This orthography is considered almost accurate or accurate and is based on more than one source, but it has not been verified by a competent user of the language.",
    "tooltip_validity_v": "This orthography is complete and it has been reviewed by a competent user of the language.",

    "tooltip_status_primary": "The main orthography of a language",
    "tooltip_status_secondary": "A less-frequently–used orthography",
    "tooltip_status_local": "A less common orthography limited to a small geographic region",
    "tooltip_status_historical": "An orthography that is no longer in use",
    "tooltip_status_transliteration": "An orthography used for transliterations",

    "filter_show_supported": "show characters the font supports",
    "filter_show_unsupported": "show characters not supported by the font",
    "notice_no_characters_visible": "Filter options hide both supported and unsupported characters of the font. Please select either or both from the options above.",
    "notice_supported_options": "These filters are only applicable if a font has been selected.",
}

// TODO need to figure out how one could make this work with reactive values
// function render (key, values) {
//     let txt = texts[key];

//     if (typeof(values) === "undefined") {
//         return txt
//     }

//     try {
//         if (values.length > 0) {
//             for (let val of values) {
//                 // Replace the first found this round
//                 txt = txt.replace("%", val)
//             }
//         }
//     } catch (e) {
//         console.error(e)
//     }

//     return txt
// }

export default {

    /**
     * Expose all texts plain
     */
    t: texts,

    // /**
    //  * Render a text, optionally pass an array of values to replace into the text
    //  */
    // render: render,

    /**
     * Shortcut to return a working v-tooltip="tooltip(...)" config attribute
     * @param {*} key 
     * @returns 
     */
    tooltip: function (key /*, values*/) {
        return {
            // content: render(key, values),
            content: texts[key],
            triggers: ['hover', 'click']
        }
    }
}