<template>
    <section v-if="languageList.length > 0" :class="{ 'none-supported': !dbMode && hasPreviewFont && numSupported === 0 }">
        <header :class="{ collapsed: showCollapsed }">
            <h3 v-on:click="collapsed = !collapsed">
                <span class="toggle" :class="{ collapsed: showCollapsed }"
                    :style="{ 'visibility': (searchResults.length > 0) ? 'hidden' : 'visible' }">
                    <i class="icon-arrow"></i>
                </span>

                <label v-if="dbMode">
                    <span class="heading-s">{{ script }} script
                        <span v-if="chars.length == 0" class="count hide-on-tablet">
                            ({{ languageList.length }} {{ $helpers.pluralized('language', languageList.length) }})
                        </span>
                    </span>
                </label>

                <label v-else class="checkcircle heavy">
                    <input ref="script_checkbox" type="checkbox" v-on:change="toggleScriptLanguages(script)"
                        :checked="(allSelected || someSelected)" :class="{ 'semi-checked': someSelected }">
                    <span class="heading-s">
                        {{ script }} script

                        <span v-if="hasPreviewFont" class="count hide-on-tablet">
                            ({{ numSelected }}/{{ languageList.length }}
                            {{ $helpers.pluralized('language', languageList.length) }})
                        </span>

                        <span v-else class="count hide-on-tablet">
                            ({{ languageList.length }} {{ $helpers.pluralized('language', languageList.length) }})
                        </span>
                    </span>
                </label>

                <span class="toggle-script text-button" :class="{ collapsed: collapsed }"
                    v-if="!(this.searchResults.length > 0)">
                    <span v-if="collapsed">Show <span
                            class="hide-on-desktop-small">{{ $helpers.pluralized('language', languageList.length) }}</span></span>
                    <span v-else>Close <span
                            class="hide-on-desktop-small">{{ $helpers.pluralized('language', languageList.length) }}</span></span>
                </span>
            </h3>
        </header>

        <ul class="language-list" :class="{
            'collapsed': showCollapsed,
            'search-active': searchResults.length > 0,
            'show-unsupported': showUnsupported || supportedIsos.length === 0,
        }">
            <li v-for="(language, id) in languageList" :key="id" :class="{
                'selected': isSelected(language.iso),
                'not-supported': !isSupported(language.iso) && hasPreviewFont,
                'highlighted': isHighlighted(language.iso),
                'search-result': searchMatchesLanguage(language.iso),
                // 'search-result-unhidden': language.searchUnhidden === true
            }" v-on:mouseenter="highlightedLanguages = isSelected(language.iso) ? [script + '_' + language.iso] : []"
                v-on:mouseleave="highlightedLanguages = []">
                <div v-if="dbMode">
                    <label class="radio">
                        <input type="radio" name="language" v-on:change="toggleLanguage(language.iso)"
                            :checked="isSelected(language.iso)">
                        <span>{{ language.n }}</span>
                    </label>
                    <div class="language-details" v-if="showDetails">
                        <span class="language-validity tooltip-wrapper"
                            v-tooltip="$texts.tooltip('tooltip_validity_' + language.v)">{{ language.v }}</span>
                    </div>
                </div>

                <div v-else>
                    <label class="checkcircle">
                        <input class="" type="checkbox" v-on:change="toggleLanguage(language.iso)"
                            :checked="isSelected(language.iso)">
                        <span>{{ language.n }} <span v-if="!language.supported && hasPreviewFont">(not
                                supported)</span></span>
                    </label>
                    <div class="language-details" v-if="showDetails">
                        <span class="language-validity tooltip-wrapper" v-tooltip="{
                            content: validityTooltip(language.v),
                            triggers: ['hover', 'click']
                        }">{{ language.v }}</span>
                        <router-link class="language-detail link-open-new" target="_blank"
                            :to="{ name: 'language', params: { script: encodeURI(this.script.toLowerCase()), iso: language.iso } }"><span>Detail</span></router-link>
                    </div>
                </div>
            </li>
        </ul>
        <div v-if="!collapsed && !dbMode && chars.length > 0 && languageList.length > numSupported && !showUnsupported && supportedIsos.length !== 0"
            class="toggle-show-unsupported text-button" v-on:click="showUnsupported = !showUnsupported">
            <i class="icon-arrow-right"></i>
            <span>Show {{ languageList.length - numSupported }} languages using {{ script }} script that are not supported
                by preview font</span>
        </div>

    </section>
</template>


<script>
import Hyperglot from "../hyperglot.json"

import { useAppStore } from "../stores/appStore"
import { useFontStore } from "../stores/fontStore"
import { mapState, mapWritableState, mapActions } from "pinia"

export default {
    name: "Languages",

    components: {},

    props: {
        languages: Object,
        script: String,
    },

    data: function () {
        return {
            // the state of user interaction, but search results may still
            // expand the list, e.g. for display use the computed
            // show_as_collapsed
            collapsed: true,
            showUnsupported: false
        };
    },

    mounted: function () {
        if (this.someSelected || this.allSelected) {
            this.collapsed = false
        }
    },

    computed: {
        ...mapState(useAppStore, ['dbMode',

            'searchResults',
            'includeSecondaryAndDeprecated',
            'includeHistoricalAndConstructed',
            'includeDraft',
            'includeVerified',
            'showDetails',
        ]),

        ...mapWritableState(useAppStore, ['selectedLanguages', 'highlightedLanguages']),

        ...mapState(useFontStore, ['supported',
            'family',
            'chars',
            'hasPreviewFont']),

        /**
         * Show the list as collapsed if this.collapsed is true AND there are
         * no search results active at the moment.
         */
        showCollapsed: function () {
            return !(!this.collapsed || this.searchResults.length !== 0)
        },

        supportedIsos: function () {
            if (Object.keys(this.supported).length === 0 || !(this.script in this.supported)) {
                return []
            }

            // Filter against languageList (which was filtered against UI options)
            let languageListIsos = [];
            for (let index in this.languageList) {
                languageListIsos.push(this.languageList[index].iso)
            }

            return this.supported[this.script].filter((iso) => {
                return languageListIsos.indexOf(iso) !== -1
            })
        },

        supportedScriptIsos: function () {
            return this.$helpers.languagesToList(this.supported)
        },

        // Filter languages by search and filters
        // Filters should hide the languages
        // Search hits should show them
        languageList: function () {
            let displayed = [],
                // Flag to show a language that matches search even if normally
                // hidden by filters
                searchUnhidden = false;

            for (var iso in this.languages) {
                let lang = this.languages[iso];
                const searchActive = this.searchResults.length !== 0,
                    isSearchResult = this.searchMatchesLanguage(iso);

                // Add supported flag to language
                if (!this.dbMode && this.chars.length !== 0 && this.script in this.supported) {
                    lang["supported"] = this.supported[this.script].indexOf(iso) !== -1
                }

                // Language validity
                // todo (always ignored)
                // > draft - include only if checked
                if (!this.includeDraft && lang["v"] === "d") {
                    if (!isSearchResult) {
                        console.debug("Hide draft language", lang["n"])
                        continue
                    } else {
                        searchUnhidden = true
                    }
                }

                // Language validity
                // todo (always ignored)
                // preliminary - include only if checked
                // verified - include only if checked
                if (!this.includeVerified && ["v", "p"].indexOf(lang["v"]) !== -1) {
                    if (!isSearchResult) {
                        console.debug("Hide verified language", lang["n"])
                        continue
                    } else {
                        console.error("Show", lang["n"], iso, lang["v"])
                        searchUnhidden = true
                    }
                }

                // Language status
                // living
                // > h_istorical
                // > a_ncient
                // > e_xtinct
                // > c_onstructed
                if (!this.includeHistoricalAndConstructed && lang["h"]) {
                    if (!isSearchResult) {
                        console.debug("Hide historical/constructed language", iso, lang["n"])
                        continue
                    } else {
                        searchUnhidden = true
                    }
                }

                // Filter languages which, for this script, only have secondary orthographies
                if (!this.includeSecondaryAndDeprecated) {
                    let has_primary = false
                    for (let o of lang["o"]) {
                        if (o["st"] === "p") {
                            has_primary = true
                        }
                    }

                    if (!has_primary) {
                        if (isSearchResult) {
                            searchUnhidden = true
                        } else {
                            console.debug("Hide language for script", this.script, iso, "without primary orthography")
                            continue
                        }
                    }
                }

                if (searchActive && !isSearchResult) {
                    continue
                }

                lang["searchUnhidden"] = searchUnhidden
                lang["iso"] = iso
                lang["selected"] = false

                // console.debug("List language", this.script, iso, lang)

                displayed.push(lang)
            }

            // Sort unsupported to the end, retain a-z
            displayed = displayed.sort((a, b) => {
                return a.supported < b.supported || a.n > b.n
            })

            return displayed
        },

        // Only relevant in font mode and with active font
        // This needs to perform the same filtering as languageList or the 
        // supported count can surpass the total! (:
        numSupported: function () {
            const that = this;

            if (!(this.script in this.supported)) {
                return 0
            } else {
                // Filter supported based on filters:
                let supportedVisible = this.supported[this.script].slice(0) // Copy

                // Language validity
                // todo (always ignored)
                // > draft - count if checked
                if (!this.includeDraft) {
                    supportedVisible = supportedVisible.filter((iso) => {
                        let lang = Hyperglot[that.script][iso]
                        return lang["v"] !== "d"
                    })
                }

                // Language validity
                // todo (always ignored)
                // > preliminary (always included) - count if checked
                // > verified (always included) - count if checked
                if (!this.includeVerified) {
                    supportedVisible = supportedVisible.filter((iso) => {
                        let lang = Hyperglot[that.script][iso]
                        // Filter out (true) if language is verified or preliminary
                        return ["v", "p"].indexOf(lang["v"]) !== -1
                    })
                }

                // Language status
                // living
                // > h_istorical
                // > a_ncient
                // > e_xtinct
                // > c_onstructed
                if (!this.includeHistoricalAndConstructed) {
                    supportedVisible = supportedVisible.filter((iso) => {
                        let lang = Hyperglot[that.script][iso]
                        return !("h" in lang)
                    })
                }

                return supportedVisible.length
            }
        },

        numSelected: function () { return this.selectedInThisScript.length },

        noneSelected: function () { return this.numSelected === 0 },

        someSelected: function () { return !this.noneSelected && this.numSelected !== this.languageList.length },

        allSelected: function () {
            return this.numSelected > 0 && this.numSelected === this.languageList.length
        },

        allSupportedSelected: function () {
            let that = this;
            return this.selectedInThisScript.length === this.selectedInThisScript.filter((iso) => {
                return that.supportedIsos.indexOf(iso) !== -1
            }).length
        },

        /**
         * @return [isos]
         */
        selectedInThisScript: function () {
            const that = this;
            return this.selectedLanguages.filter(scriptIso => {
                const parts = scriptIso.split("_"),
                    script = parts[0],
                    iso = parts[1],
                    isos = Object.keys(this.languages),
                    isoOnDisplay = this.languageList.filter((lang) => {
                        return lang.iso === iso
                    }).length !== 0
                    ;

                return script === that.script && isos.indexOf(iso) !== -1 && isoOnDisplay
            }).map(that.$helpers.isoWithoutScript)
        },
    },

    methods: {
        ...mapActions(useAppStore, ['addLanguages', 'setLanguage', 'removeLanguages']),

        /**
         * Determine if the search string matches in the passed in language
         * @param {*} iso 
         * @return {bool}
         */
        searchMatchesLanguage(iso) {
            return this.searchResults.indexOf(this.script + "_" + iso) !== -1
        },

        toggleLanguage: function (iso) {
            const scriptIso = this.script + "_" + iso

            console.log("toggleLanguage", iso)

            if (this.selectedInThisScript.indexOf(iso) === -1) {
                if (this.dbMode) {
                    this.setLanguage(scriptIso)
                } else {
                    this.addLanguages([scriptIso])
                }
            } else {
                this.removeLanguages([scriptIso])
            }

            if (this.dbMode) {
                this.$router.push({ "name": "language", params: { "script": this.script.toLowerCase(), "iso": iso } })
            }
        },

        _prependScript: function (iso) {
            return this.script + "_" + iso
        },

        /**
         * Toggle all languages in this script.
         */
        toggleScriptLanguages: function () {
            const that = this;

            let scriptIsos = [];

            // TODO this logic is waaaaay to complex
            console.log("all supported selected", this.allSupportedSelected)
            console.log("show unsupported", this.showUnsupported)
            console.log("has preview font", this.hasPreviewFont)
            console.log("all selected", this.allSelected)
            console.log("(this.showUnsupported || !this.hasPreviewFont && this.allSelected)", (this.showUnsupported || !this.hasPreviewFont && this.allSelected))

            // Only relevant in font mode
            if (this.noneSelected) {
                console.debug("add all to selection")
                // If none selected, add all
                scriptIsos = this.languageList.map(item => { return item["iso"] }).map(this._prependScript)

                if (this.hasPreviewFont) {
                    // If there are supported and unsupported in this script
                    // filter against supported if in font mode
                    if (!this.showUnsupported) {
                        // When unsupported are hidden, add all supported
                        scriptIsos = scriptIsos.filter((scriptIso) => {
                            return that.supportedScriptIsos.indexOf(scriptIso) !== -1
                        })
                    }

                    // If the entire script has no supported languages select
                    // them all
                    if (this.numSupported === 0) {
                        for (let lang of this.languageList) {
                            scriptIsos.push(this.script + "_" + lang.iso)
                        }
                    }
                }
                this.addLanguages(scriptIsos)
            } else if (this.allSupportedSelected || (this.showUnsupported || !this.hasPreviewFont && this.allSelected)) {
                console.debug("remove all selected")
                // If all selected, remove all
                scriptIsos = this.selectedInThisScript.map((iso) => { return this.script + "_" + iso })
                this.removeLanguages(scriptIsos)
            } else if (this.allSelected && this.hasPreviewFont && this.numSupported === 0) {
                console.debug("unselect all")
                // Unselect all (unsupported)
                for (let lang of this.languageList) {
                    scriptIsos.push(this.script + "_" + lang.iso)
                }
                this.removeLanguages(scriptIsos)
            } else if (this.someSelected) {
                console.debug("add the missing to selected")
                // If some are selected, add the missing rest
                scriptIsos = this.languageList.filter(iso => {
                    return that.selectedInThisScript.indexOf(iso) === -1
                }).map(item => { return item["iso"] }).map(this._prependScript)

                // Filter against supported if in font mode
                if (this.hasPreviewFont) {
                    scriptIsos = scriptIsos.filter((scriptIso) => {
                        return that.supportedScriptIsos.indexOf(scriptIso) !== -1
                    })
                }

                this.addLanguages(scriptIsos)

                // Explicitly set checkbox, otherwise it'll just use native boolean behaviour
                this.$refs.script_checkbox.checked = true
            }
        },

        isSelected: function (iso) {
            return this.selectedLanguages.indexOf(this.script + "_" + iso) !== -1
        },

        isSupported: function (iso) {
            return this.supportedIsos.indexOf(iso) !== -1
        },

        isHighlighted: function (iso) {
            return this.highlightedLanguages.indexOf(this.script + "_" + iso) !== -1
        },
    },

    watch: {
        /**
         * When a new value for supported is set select all those languages,
         * e.g. when a new font is picked
         */
        "supported": {
            deep: true,
            immediate: true,
            async handler(val) {
                const that = this;
                if (!this.dbMode && val.length === 0) {
                    that.selectedLanguages = []
                } else {
                    // Intersect the new list of supported isos with this language's
                    // actually shown languages, and select those.

                    // Just setting all new supported isos as selected would set
                    // languages as selected which are hidden in the UI on the
                    // left because they are filtered out.
                    
                    const newSupportedScriptIsos = that.$helpers.languagesToList(val)
                    
                    let visibleAndSupported = [];
                    for (let lang of that.languageList) {
                        const scriptIso = that.script + "_" + lang.iso
                        if (newSupportedScriptIsos.indexOf(scriptIso) !== -1) {
                            visibleAndSupported.push(scriptIso)
                        }
                    }

                    this.addLanguages(visibleAndSupported)
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
section {
    margin-bottom: calc($gutter*0.5);


}

.none-supported {
    header {
        color: var(--disabled);
    }
}

header {
    transition: margin 0.2s ease-out;
    margin-bottom: 0.5rem;
}

.toggle {
    opacity: 1 !important;
    color: var(--link);
}

h3 {
    @extend .heading-s;
    cursor: pointer;
    transition: $transition;

    * {
        font-weight: inherit !important;
    }


    .toggle:hover,
    .toggle-script:hover {
        color: $blue;
    }

    .count {
        white-space: nowrap;

        span {
            margin: 0;
            padding: 0;
        }
    }
}

.toggle {
    display: inline-block;
    width: 1em;
    height: 1em;
    margin-left: -1.25em;
    margin-right: 0.25em;
    line-height: 1;
    text-align: center;
    opacity: 0;
    transition: opacity 0.1s ease-out;

    @include mobile {
        margin-left: 0;
        opacity: 1;
    }

    i {
        position: relative;
        top: -3px;
    }

    i:before {
        transition: all 0.1s ease-out;
        transform-origin: 45% 45%;
        font-size: $font-size-m;
        transform: rotate(90deg);
    }

    &.collapsed {
        i:before {
            transform: rotate(0);
        }
    }
}

ul {
    margin: 0 0 $gutter;
    line-height: 1.6;
    clear: both;

    &.hide-historical-and-constructed {

        .historical,
        .constructed {
            display: none;
        }
    }

    &.hide-unsupported .not-supported {
        display: none;
    }

    li.not-supported {
        label span {
            @extend .italic;
            color: var(--disabled);
        }
    }

    &.search-active {
        li {
            display: none;
        }

        li.search-result {
            display: flex !important;
        }

        // li.search-result-unhidden {
        //     flex-wrap: wrap;

        //     &:after {
        //         @extend .italic;
        //         content: "(Result without active filters)";
        //         color: var(--disabled);
        //         margin-left: 2em;
        //         display: block;
        //     }
        // }
    }
}

li {
    margin-right: 0.2em;
    display: block;

    >div {
        display: flex;
        width: 100%;
    }

    &.not-supported {
        display: none;
    }
}

.language-validity {
    @include font(800);
    padding: 0;
    margin: 0;
    border-radius: 100%;
    display: inline-block;
    width: 1.25em;
    height: 1.25em;
    line-height: 1.25em;
    text-transform: uppercase;
    border: 1px solid var(--text);
    text-align: center;
    position: relative;

    // make the element bigger so it's easier to hover
    &:before {
        position: absolute;
        width: 2em;
        height: 2em;
        margin: -0.5em 0 0 -0.65em;
        content: " ";
    }
}

.language-detail {
    @extend .text-button;
    margin-left: 1em;
}

.language-details {
    margin-left: auto;
}

.language-info {
    margin-left: 1rem;
}

header {
    clear: both;
    margin-bottom: 20px;
}

.language-list {
    &.collapsed {
        display: none;
    }

    &.show-unsupported li.not-supported {
        display: flex;
    }
}

.language-list li {
    transition: background 0.2s ease-out;
    margin: 5px -10px;
    padding: 5px 10px;

    // Better spacing for nested languages' checkboxes if the label breaks to
    // several lines
    &>div>label {
        display: flex;
        align-items: flex-start;

        input[type=radio]+span:before,
        input[type=checkbox]+span:before {
            top: 0;
        }

        input[type=checkbox]+span:after {
            top: 2.5px;
        }

        input[type=radio]+span:after {
            top: 5.5px;
        }
    }

    // Hovered or context-hovered elsewhere
    &.highlighted {
        background: var(--link-muted);
    }

    // Checkbox selected
    // &.selected:hover,
    // &.selected:focus {
    //   background: var(--link);
    //   color: var(--background);
    //   cursor: zoom-in;
    // }
}

.toggle-script {
    font-weight: 800 !important;
    float: right;
    margin-top: 4px;

    @include mobile {
        display: none;
    }
}

.toggle-show-unsupported {
    padding-left: $gutter*0.5;
    margin-top: -$gutter*0.75;
    margin-bottom: $gutter*0.75;
    display: flex;

    i {
        color: var(--link);
        transform-origin: center;
        position: relative;
        top: -5px;
        font-size: 16px;
        margin-right: 0.5rem;
        margin-left: -0.5rem;
        display: inline-block;
        transform: rotateZ(0);
    }

}

.checkcircle.heavy {
    margin-left: -4px;
}
</style>