<template>
    <fieldset>

        <div class="flex-spread">
            <Search />
            <button class="text-button" id="filters-toggle" v-on:click="toggleFilters">
                <i class="icon-settings"></i>
            </button>
        </div>

        <div id="filters" :class="{ collapsed: collapsed }">

            <label class="checkbox heavy">
                <input v-model="includeHistoricalAndConstructed" type="checkbox" class="switch-input" />
                <span>show <span class="tooltip-link" v-tooltip="$texts.tooltip('tooltip_filters_historical')">historical and constructed languages</span></span>
            </label>

            <label class="checkbox heavy">
                <input v-model="includeSecondaryAndDeprecated" type="checkbox" class="switch-input" />
                <span>show <span class="tooltip-link" v-tooltip="$texts.tooltip('tooltip_filters_secondary')">secondary and deprecated orthographies</span></span>
            </label>

            <label class="checkbox heavy">
                <input v-model="includeDraft" type="checkbox" class="switch-input" />
                <span>show <span class="tooltip-link" v-tooltip="$texts.tooltip('tooltip_filters_draft')">unverified language data</span></span>
            </label>

            <label class="checkbox heavy">
                <input v-model="includeVerified" type="checkbox" class="switch-input"/>
                <span>show <span class="tooltip-link" v-tooltip="$texts.tooltip('tooltip_filters_verfied')">verified language data</span></span>
            </label>

            <label class="checkbox heavy">
                <input v-model="showDetails" type="checkbox" class="switch-input" />
                <span>show <span class="tooltip-link" v-tooltip="$texts.tooltip('tooltip_filters_details')">details in the
                        language list below</span></span>
            </label>
        </div>
    </fieldset>
</template>

<script>
import Search from "./Search"
import { useAppStore } from "../stores/appStore"
import { mapWritableState } from "pinia"
import "../../node_modules/clipboard-polyfill/overwrite-globals"

export default {
    name: "Filters",

    components: { Search },

    data: function () {
        return {
            collapsed: true,
            options: {}
        };
    },

    computed: {
        ...mapWritableState(useAppStore, ['search',
            'includeHistoricalAndConstructed',
            'includeSecondaryAndDeprecated',
            'includeDraft',
            'includeVerified',
            'showDetails',
        ])
    },

    methods: {
        toggleFilters: function () {
            this.collapsed = !this.collapsed
            this.$analytics.trackEvent("Interaction", "Settings", "Toggle", this.collapsed ? "opened" : "closed")

        }
    }

};
</script>

<style lang="scss" scoped>
fieldset {
    >label {
        @extend %label;
        display: block;
        margin-bottom: 6px;
    }
}

#filters-toggle {
    width: 32px;
}

#search {
    flex-grow: 2;
    margin-right: $gutter*0.25;
}

#filters {
    margin-top: $gutter*0.25;
    display: flex;
    flex-direction: column;

    &.collapsed {
        display: none;
    }

    >* {
        margin-bottom: $gutter*0.25;
    }
}

.copy-button,
.paste-button {
    @extend .button-dashed;
    @extend %label;
    line-height: 34px !important;
    font-size: 10px;
    letter-spacing: 0.1em;
    margin: 0.5rem 0.5rem 0.5rem 0 !important;
    padding: 0.2em 2.5em 0 !important;

    &.disabled {
        border: 1px dashed $grey !important;
        color: $grey !important;
        cursor: not-allowed;
    }
}

.paste-ui {
    margin-bottom: 0;
}

.copy-ui {
    margin-top: 0;
}

#clipboard-helper {
    font-family: inherit;
    display: block;
    border: 1px solid $blue-lighter;
    background: $white;
    width: 100%;
    max-width: 30rem;
    height: 7rem;
    padding: 0.5em;
    line-height: 1.5;
    margin-top: 0.5rem;

    &:hover,
    &:focus,
    &:active {
        outline: 1px solid $blue;
    }

    +p {
        clear: both;
        display: block;
        max-width: 30rem;
    }
}

.clipboard-message {
    @extend .ui-feedback;
    opacity: 1;
}

.icon-settings {
    font-size: 24px;
}
</style>