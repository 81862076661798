<template>
    <fieldset id="font">
        <div>

            <LocalFont id="local-font" v-if="!(hasPreviewFont || previewFontLoading)">
                <p class="bold italic color-link">
                    <span class="font-file-label">
                        Drop your
                        <span class="tooltip-link" v-on:click="onLocalButtonClicked" v-tooltip="$texts.tooltip('tooltip_local_font')">local font</span>
                        here
                    </span>
                </p>

                <p class="bold italic color-link">
                    or
                    <Multiselect v-model="fontSelect" @select="onFontSelect" :options="fontList" :searchable="false"
                        :close-on-select="true" :allow-empty="false" track-by="name" label="name" open-direction="bottom"
                        placeholder="select one" />
                </p>

            </LocalFont>

            <div id="selected-font" class="input" v-else>
                <span v-if="previewFontLoading">Loading</span>
                <span v-else>{{ family }}</span>

                <button class="remove-button round-button" id="remove-font" v-on:click="removeFont">
                    <i class="icon-checkmark-small-filled-x"></i>
                </button>
            </div>

        </div>
    </fieldset>
</template>


<script>
import Multiselect from "../../node_modules/vue-multiselect"
import LocalFont from "./LocalFont"
import { useFontStore } from "../stores/fontStore"
import { mapWritableState, mapActions } from "pinia"

import RosettaFonts from "../fonts.json"
import Font from "../Font"


export default {
    name: "FontSelector",

    components: {
        Multiselect,
        LocalFont,
    },

    data: function () {
        return {
            font: null,
            fontSelect: null,
            fontList: RosettaFonts,
        };
    },

    computed: {
        ...mapWritableState(useFontStore, ['family',
            'supported', 
            'chars', 
            'previewFontLoading', 
            'hasPreviewFont',
            'selectedLanguages']),
    },

    methods: {
        ...mapActions(useFontStore, ['setSupported', 'clearFont']),

        /**
         * Callback for the font dropdown selection
         */
        onFontSelect: function (option) {
            this.previewFontLoading = true
            console.log("SELECTING", option)

            if (option.fontface && option.opentype) {
                console.warn("select already loaded", option);
                this.font = option
                this.onFontLoaded(option);
            } else {
                console.debug("select new webfont");
                this.loadWebfont(option);
            }
            this.fontSelect = option;
            this.$analytics.trackEvent("Select Font", "Font", "Preset", option.name)
        },
        /**
         * Load one of the predetermined webfonts from the list
         */
        loadWebfont: function (font) {
            let that = this;
            this.font = new Font(font.name, { files: font.files });
            this.font.load().then(function (result) {
                // To this webfont option save the opentype and fontface
                that.onFontLoaded(result);
                let current = that.fontList[that.fontList.indexOf(that.fontSelect)];
                if (
                    "opentype" in current === false ||
                    "fontface" in current === false ||
                    "chars" in current === false
                ) {
                    current.opentype = result.opentype;
                    current.fontface = result.fontface;
                    current.chars = result.chars;
                    that.hasPreviewFont = true
                }
            });
        },


        /**
         * The callback for when new Font() has finished loading, either from one
         * or more webfonts or from a local file buffer
         * Once a font has been loaded and its opentype font and fontface are
         * stored this function can be explicitly called to update the UI with this
         * font
         */
        onFontLoaded: function (result) {
            console.debug("onFontLoaded", result, this.font.family);
            let that = this;

            this.previewFontLoading = true

            let data = result.chars.map((val) => encodeURIComponent(val));
            fetch(process.env.VUE_APP_API_URI + "support", {
                method: "POST",
                body: "c=" + data.join("&c="),
                headers: {
                    "Content-type": "application/x-www-form-urlencoded",
                },
            })
                .then((res) => res.json())
                .then(function (data) {
                    console.log("SUPPORT", data, "sup", that.supported, "fam", result.fontface.family);
                    that.chars = result.chars
                    that.family = result.fontface.family
                    that.setSupported(data)
                    that.previewFontLoading = false
                    that.hasPreviewFont = true

                    return that.supported
                })
                .catch((error) => {
                    console.error(error);
                    that.$notify({
                        group: "messages",
                        type: "error",
                        title: "Server not reachable.",
                        text:
                            "The server for processing instance request appears to be unreachable. Please try again in a moment.",
                        duration: -1,
                    });
                    that.previewFontLoading = false
                });


            return result;
        },

        showError: function (e) {
            this.$notify({
                group: "messages",
                type: "error",
                title: "Something went wrong loading the supplied font: " + e,
            });
            this.clearFont(true)
        },

        removeFont: function () {
            this.font = null;
            this.fontSelect = null;
            this.clearFont(true)
        },

        // Fairly hacky way of making click on the nested component happen
        onLocalButtonClicked: function () {
            try {
                document.getElementById("font-file").click()
            } catch (e) {
                console.error(e)
            }
        },
    },
};
</script>

<style lang="scss" scoped>
#local-font {

    p {
        margin-top: 0;

        span {
            display: inline-block;
        }
    }

    .tooltip-link {
        cursor: pointer;
    }
}

#font {
    .multiselect {
        display: inline-block;
        width: auto;
        height: auto;
        min-width: 0;
        min-height: 0;
        line-height: inherit;
        border: 0;
        padding-right: 24px;
        background: inherit;

        .multiselect__tags {
            line-height: inherit;
            height: auto;
            width: auto;
        }

        :deep(.multiselect__placeholder) {
            @extend .tooltip-link;
            border-bottom-style: solid;
            border-bottom-color: var(--link);
            line-height: inherit;
            display: inline !important; // Also when dropdown is open, to force the width not collapsing
        }

        :deep(.multiselect__content-wrapper) {
            width: auto;
            min-width: 20rem;
            transform: translateX(-50%);
            left: 50%;
            margin-left: -12px;
        }

        :deep(.multiselect__select) {
            width: auto;
            height: auto;
            line-height: inherit;

            &:before {
                top: 3px;
            }
        }
    }
}

#selected-font {
    display: block;
    background: var(--background-dimmed);
    margin: 0;
}

#remove-font {
    position: absolute;
    right: 0;
    top: 0;
    width: $button-height - 4px;
    height: $button-height - 4px;
    top: 0;
    right: 0;
    border: 0;
    background: var(--background-dimmed);
    z-index: 9;
    color: var(--link);

    &.round-button {
        line-height: 1;

        i {
            color: var(--link);
            font-size: 20px;
            top: -0.05em;
        }
    }
}
</style>