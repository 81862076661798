/**
 * A untility wrapper that combines several font files into one font object
 */

import * as opentype from "../node_modules/opentype.js/dist/opentype"
import UnicodeRange from "./UnicodeRange"

export default class {

    /**
     * Pass in an array of objects with paths as keys of an unicode range 
     * descriptor, e.g. [ { "foo/bar.woff": "U+0041,U+0062-0AF0" }, {...} ]
     * 
     * @returns an object with .load method
     */
    constructor(family, src) {
        console.debug("NEW FONT", family, src)
        this.family = family.replace(",", "", family)
        this.fonts = [] // A list of parsed opentype font objects
        this.opentype = null
        this.fontface = null

        if ("files" in src) {
            this.files = src.files
        } else if ("buffer" in src) {
            this.buffer = src.buffer
        }

        return this
    }

    /**
     * @returns Promise that fulfills when all font files/buffers are parsed.
     * The promise success method receives an object with "fontface" and 
     * "opentype" properties of this font
     */
    load() {
        if (this.files) {
            return this.loadFonts()
        }
        if (this.buffer) {
            return this.loadBuffer()
        }

        // Woopsie daisy!
        return false
    }

    /**
     * If a ArrayBuffer was passed in, make the font from that
     * 
     * @returns Promise
     */
    loadBuffer() {
        let that = this

        return new Promise(function (resolve, reject) {
            try {
                let font = opentype.parse(that.buffer)
                that.fonts.push(font)

                that.renderFontFace(that.family, that.buffer,
                    {
                        // unicodeRange: range
                    }, function (fontface) {

                        if (fontface === false) {
                            reject("Failed to parse the passed in font file.")
                            return
                        }

                        document.fonts.add(fontface);
                        that.fontface = fontface
                        that.opentype = font
                        resolve({
                            "fontface": fontface,
                            "opentype": font,
                            "chars": that.getChars(),
                            "display_name": that.getDisplayName()
                        })
                    })
            } catch (e) {
                reject(e)
            }
        })
    }

    /**
     * If a list of one or more files was passed in load all files and combine
     * them according to their defined unicode ranges (also passed in with the
     * files!) to abstract the several fonts into one Font object for easy
     * charset comparison etc.
     * 
     * @returns Promise
     */
    loadFonts() {
        let that = this,
            proms = []

        for (let f = 0; f < that.files.length; f++) {
            let path = that.files[f].file,
                range = that.files[f].range

            console.debug("Load font file", path)

            // Load each passed in file as a Promise that resolves when
            // the fontface has been added to the document
            proms.push(

                new Promise(function (resolve, reject) {

                    // Load the file with Opentype
                    opentype.load(path, function (error, font) {
                        if (error) {
                            //
                            console.error("Opentype error", error)
                        } else {
                            that.fonts.push(font)

                            // Make the FontFace
                            let urlpath = "url('" + path + "')"
                            that.renderFontFace(that.family, urlpath, {
                                unicodeRange: range
                            }, function (fontface) {
                                if (fontface === false) {
                                    reject()
                                }
                                document.fonts.add(fontface);
                                that.fontface = fontface
                                that.opentype = font
                                that.getDisplayName()
                                resolve({
                                    "fontface": fontface,
                                    "opentype": font,
                                    "chars": that.getChars(),
                                    "display_name": that.getDisplayName()
                                })
                            });

                            that.files[f].unicodes = new UnicodeRange(range)
                        }
                    })
                })
            )
        }

        // When all files and unicode ranges have been added to the document
        // return the CSS font family to use this font with
        return Promise.all(proms).then(function (/*res*/) {
            // res is all the resolved promises

            return {
                "chars": that.getChars(),
                "fontface": that.fontface,
                "family": that.family,
            }
        })
    }

    /**
     * Helper to add and load a new FontFace
     * 
     * @param {*} family 
     * @param {*} urlOrBuffer 
     * @param {*} obj 
     * @param {*} callback 
     */
    renderFontFace(family, urlOrBuffer, obj, callback) {
        try {
            let fontface = new FontFace(family, urlOrBuffer, obj);

            fontface.load().then(function () {
                document.fonts.add(fontface)
                if (typeof (callback) !== "undefined") {
                    callback(fontface)
                }
            }).catch(function (e) {
                console.error("A fontface.load failed:", e)
                console.debug(family)
                console.debug(urlOrBuffer)
                console.debug(obj)
                callback(false)
            })
            // console.log("RETURN FONTFACE", fontface)
            // return fontface;
        } catch (e) {
            console.error("B fontface.load failed:", e)
            console.debug(family)
            console.debug(urlOrBuffer)
            console.debug(obj)
            console.error(e)
            return false
        }
    }

    /**
     * The juicy bit: From whatever font file(s)/buffers parsed check what
     * chars this Font abstraction supports
     */
    getChars() {
        let unicodes = []
        for (var i = 0; i < this.fonts.length; i++) {
            const glyphs = this.fonts[i].glyphs.glyphs
            let chars = []

            Object.keys(glyphs).map(function (key /*, index*/) {
                let unis = glyphs[key].unicodes;
                if (unis) {
                    for (var u = 0; u < unis.length; u++) {
                        // Use 0x hex notation and fromCodePoint (instead of 
                        // fromCharCode and the int value) to ensure support for
                        // 16 bit unicodes, e.g. in Chakma
                        chars.push(String.fromCodePoint("0x" + unis[u].toString(16)));
                    }
                }
            })
            chars = chars.filter(function (char) {
                return typeof (char) !== "undefined"
            })
            unicodes = unicodes.concat(chars)
        }
        console.debug(unicodes.length + " unicodes in the font")
        return unicodes
    }

    getDisplayName() {
        if (!("names" in this.opentype)) {
            return this.family
        }

        if ("preferredFamily" in this.opentype.names && "preferredSubfamily" in this.opentype.names) {
            return this._getName(this.opentype.names.preferredFamily) + " " + this._getName(this.opentype.names.preferredSubfamily)
        }

        if ("fontFamily" in this.opentype.names && "fontSubfamily" in this.opentype.names) {
            return this._getName(this.opentype.names.fontFamily) + " " + this._getName(this.opentype.names.fontSubfamily)
        }

        if ("fullName" in this.opentype.names) {
            return this._getName(this.opentype.names.fullName)
        }
    }

    _getName(obj) {
        if ("en" in obj) {
            return obj.en
        }
        // Fallback to whatever is the first key
        return obj[Object(obj).keys()[0]]
    }
}