/**
 * General utilities for wielding javascript like string/array/object functions
 */
export default {

    numberWithSeparator: function (x, separator) {
        if (typeof (separator) === "undefined") {
            separator = "."
        }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    },

    capitalize: function (input) {
        if (!input) {
            console.warn("Cannot capitalize", input)
            return ""
        }

        // Edge cases like: Ge'ez/Fidel ('ez should not be capitalized!)
        // Or: Coptic/Nubian
        // Or: Egyptian Hieroglyphs

        const split_by_replace_with = { " ": " ", "/": "/" }

        for (let reg in split_by_replace_with) {
            let repl = split_by_replace_with[reg]
            let expr = new RegExp(reg, "g")
            input = input.split(expr).map(str => {
                return str.substr(0, 1).toUpperCase() + str.substr(1)
            }).join(repl)
        }

        return input
    },

    // As per https://stackoverflow.com/a/16227294/999162
    arrayIntersect: function (a, b) {
        var t;
        if (b.length > a.length) t = b, b = a, a = t; // indexOf to loop over shorter
        return a.filter(function (e) {
            return b.indexOf(e) > -1;
        });
    },

    arrayUnique: function (arr) {
        if (arr.length) {
            return arr.filter(function (value, index, self) {
                return self.indexOf(value) === index;
            })
        } else {
            return []
        }
    },

    // Via https://stackoverflow.com/a/48245181/999162
    arrayShuffle(array) {
        const result = [], itemsLeft = array.concat([]);

        while (itemsLeft.length) {
            const randomIndex = Math.floor(Math.random() * itemsLeft.length);
            const [randomItem] = itemsLeft.splice(randomIndex, 1); // take out a random item from itemsLeft
            result.push(randomItem); // ...and add it to the result
        }

        return result;
    },

    // Via https://developer.mozilla.org/en-US/docs/Glossary/Base64
    async bytesToBase64DataUrl(bytes, type = "application/octet-stream") {
        return await new Promise((resolve, reject) => {
            const reader = Object.assign(new FileReader(), {
                onload: () => resolve(reader.result),
                onerror: () => reject(reader.error),
            });
            reader.readAsDataURL(new File([bytes], "", { type }));
        });
    },

    // Via https://developer.mozilla.org/en-US/docs/Glossary/Base64
    async dataUrlToBytes(dataUrl) {
        const res = await fetch(dataUrl);
        return new Uint8Array(await res.arrayBuffer());
    },

}