<template>
    <div class="modal" v-if="is_open" v-on:click.self="close">
        <div class="modal-content">
            <slot v-on="{'close-modal': close}"></slot>
            <button class="modal-close" v-on:click="close">Close</button>
        </div>
    </div>
</template>

<script>
export default {

    data: function () {
        return {
            is_open: false
        }
    },

    methods: {
        open: function () {
            this.is_open = true
        },
        
        close: function () {
            this.is_open = false
        },
    }
}
</script>

<style lang="scss" scoped>
.modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.85);
    z-index: 999999;
}

.modal-content {
    @include gutter(0.75, 0.65, "padding");

    background: $highlight;
    border-radius: 2px;
    color: $black;
    max-width: 90vw;
    max-height: 90vh;
    overflow: auto;
    width: 33%;
    min-width: 45rem;
    top: 50%;
    left: 50%;
    position: relative;
    transform: translate(-50%, -50%);

    // On the top heading keep space for the close button to not overlap
    :deep(h1, h2, h3) {
        padding-right: 3.5rem;
    }

    @include tablet {
        min-width: 0;
        width: auto;
        max-width: 90vw;
    }

    .modal-close {
        @include gutter(0.75, 0.65, "padding");
        @extend .text-button;
        color: $link;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 99;

        &:hover,
        &:focus {
            color: $black !important;
        }
    }
}
</style>