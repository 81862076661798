<template>
    <aside class="footer-content">
        <p id="footer-imprint">
            <router-link :to="{ name: 'about' }">About Hyperglot</router-link>
            /
            <a href="https://github.com/rosettatype/hyperglot/">Hyperglot on GitHub</a>
            /
            <a href="mailto:info@rosettatype.com?subject=Hyperglot website feedback">Send
                feedback</a>
            /
            <a href="//rosettatype.com" target="_blank">Rosetta type</a>
        </p>

        <p id="footer-credit">Design © Rosetta Type Foundry, 2021.<br>
            Language data is licenced under the GNU General Public Licence, <nobr>version 3.0.</nobr><br>
            Hyperglot version {{ version }}
        </p>
    </aside>
</template>

<script>
import Version from "../hyperglot-version.json"

export default {
    name: "Imprint",
    data: function () {
        return {
            version: Version
        }
    },
}
</script>