<template>
    <section>
        <h2>{{ name }} in the {{ script }} script</h2>
        <header>
            <p v-if="autonym">Autonym: <span class="autonym" v-bind:data-script="script">{{ autonym }}</span></p>
            <p>Data validity: <span class="tooltip-wrapper tooltip-link"
                    v-tooltip="$texts.tooltip('tooltip_validity_' + validity.substr(0, 1))">{{ capitalize(validity) }}</span>
            </p>
            <p>Orthography status: <span class="tooltip-wrapper tooltip-link"
                    v-tooltip="$texts.tooltip('tooltip_status_' + status)">{{ capitalize(status) }}</span></p>
            <small>{{ note }}</small>
        </header>

        <div v-if="Object.keys(baseChars).length">
            <h3>Base characters</h3>
            <CharacterList :chars="baseChars" :alternates="alternates" class="character-list" :aux="false" :mark="false"
                :lang="lang"></CharacterList>
        </div>
        <div v-if="baseCount">
            <h3>Base characters</h3>
            <CharacterListPlaceholder :count="baseCount"></CharacterListPlaceholder>
        </div>

        <div v-if="Object.keys(auxChars).length">
            <h3>Auxiliary characters</h3>
            <CharacterList :chars="auxChars" :alternates="alternates" class="character-list" :aux="true" :mark="false"
                :lang="lang"></CharacterList>
        </div>
        <div v-if="auxCount">
            <h3>Auxiliary characters</h3>
            <CharacterListPlaceholder :count="auxCount"></CharacterListPlaceholder>
        </div>

        <div v-if="Object.keys(markChars).length">
            <h3>Marks</h3>
            <CharacterList :chars="markChars" :alternates="alternates" class="character-list" :aux="false" :mark="true"
                :lang="lang"></CharacterList>
        </div>
        <div v-if="markCount">
            <h3>Marks</h3>
            <CharacterListPlaceholder :count="markCount"></CharacterListPlaceholder>
        </div>

        <div v-if="requirements" class="design-requirements-section">
            <h3><span class="tooltip-wrapper tooltip-link" v-tooltip="{
                content: 'This character may require a localized design for this orthography.',
                triggers: ['hover', 'click']
            }">Design requirements</span></h3>
            <ul class="design-requirements design-requirements-wrapper"
                :class="{ 'no-numbering': requirements.length === 1 }"
                :data-language-names="[script + '_' + iso]">
                <li v-for="(note, id) in requirements" :key="id">
                    {{ note }}
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
import CharacterList from "./CharacterList"
import CharacterListPlaceholder from "./CharacterListPlaceholder"

export default {
    name: "Orthography",

    components: {
        CharacterList,
        CharacterListPlaceholder,
    },

    props: [
        'name',
        'status',
        'script',
        'iso',

        'base',
        'aux',
        'marks',

        // Counts for these while still loading
        'baseCount',
        'auxCount',
        'markCount',

        'note',
        'validity',
        'autonym',
        'speakers',
        'requirements',
        'alternates',
        'lang'
    ],

    computed: {
        // Transformed this.base formatted for CharacterList
        baseChars: function () {
            return this._chars(this.base)
        },

        // Transformed this.aux formatted for CharacterList
        auxChars: function () {
            return this._chars(this.aux)
        },

        // Transformed this.marks formatted for CharacterList
        markChars: function () {
            return this._chars(this.marks)
        },
    },

    methods: {

        /**
         * Transform base or aux chars into an object with the chars as keys,
         * and an empty array as their values (since we don't need to know 
         * their scriptIso inside the CharacterList here since there is no
         * highlighting since we are viewing one language at a time).
         * 
         * @param {Array} chars 
         */
        _chars: function (chars) {
            let sorted = {}
            for (let char of chars) {
                sorted[char] = ['_']
            }
            return sorted
        },

        capitalize: function (input) {
            return this.$utils.capitalize(input)
        },

        validityTooltip: function (level) {
            if (!level) {
                return ""
            }
            switch (level.toLowerCase()) {
                case "todo":
                case "t":
                    return "This orthography has not been properly entered.";
                case "draft":
                case "d":
                    return "This orthography has been collected in the database but is lacking review.";
                case "preliminary":
                case "p":
                    return "This orthography is considered almost accurate or accurate and is based on more than one source, but it has not been verified by a competent user of the language.";
                case "verified":
                case "v":
                    return "This orthography is complete and it has been reviewed by a competent user of the language.";
            }
            return "The status of this orthography data.";
        },
    }
}
</script>

<style lang="scss" scopred>
header {
    margin-bottom: 3rem;

    p+p {
        margin-top: 0;
    }
}

h3 {
    margin-bottom: 0.25rem;
}

.character-list {
    margin-bottom: 3rem;
}

.autonym {
    font-family: "Noto Sans";
    letter-spacing: 0.075em;

    &[data-script="Arabic"],
    &[data-script="Bengali"],
    &[data-script="Devanagari"],
    &[data-script="Gurmukhi"],
    &[data-script="Syriac"] {
        letter-spacing: 0;
    }
}
</style>