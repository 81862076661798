<template>
    <div>

        <!-- The visual selected state + x button -->
        <div id="selection" v-if="this.font">
            <input type="text" :value="fontDisplayName">
            <button v-if="!!font" class="remove-button" id="remove-font" v-on:click="removeFont">
                <i class="icon-checkmark-small-filled-x"></i>
            </button>
        </div>

        <!-- The visual drop zone -->
        <fieldset id="dropzone" :class="{ 'highlight': dragging }" v-else>
            <slot></slot>
            <div id="dropzone-notice-on-drag" class="italic bold">Drop that font of yours right here</div>
        </fieldset>

        <!-- visually hidden -->
        <form id="local-font-selector" enctype="multipart/form-data">
            <input autocomplete="off" type="file" id="font-file" accept=".ttf, .otf" v-on:change="onFileInput" />
        </form>

    </div>
</template>


<script>
import { useFontStore } from "../stores/fontStore"
import { mapWritableState, mapActions } from "pinia"

import RosettaFonts from "../fonts.json"
import Font from "../Font"


export default {
    name: "FontSelector",

    data: function () {
        return {
            font: null,
            fontDisplayName: "",
            fontSelect: null,
            fontList: RosettaFonts,
            dragging: false,
        };
    },

    computed: {
        ...mapWritableState(useFontStore, ['family',
            'supported', 'chars', 'previewFontLoading', 'hasPreviewFont'
        ]),
    },

    mounted: async function () {
        const that = this,
            dz = document.getElementById("dropzone");

        document.addEventListener("dragover", this.onDrag);
        try {
            document.querySelector("body").addEventListener("drop", this.onDrop);
        } catch (e) {
            console.error("Probably another error prior prevented the LocalFont component from mounting")
            console.error(e)
        }

        window.addEventListener("dragexit", this.hideDragUI);
        window.addEventListener("dragend", this.hideDragUI);
        window.addEventListener("dragleave", function (e) {
            // Needed so Safari doesn't spasm out :(
            if (e.target === dz) {
                return false;
            }
            that.hideDragUI()
        });
        window.addEventListener("mouseleave", this.hideDragUI);

        // Check if we should restore an previously loaded localFont from the browser storage
        try {
            const localFont = window.localStorage.getItem("localFont"),
                localFontFamily = window.localStorage.getItem("localFontFamily");
            
            let localFontBuffer;

            if (localFont) {
                localFontBuffer = await this.$utils.dataUrlToBytes(localFont)
                console.log("INIT LocalFont", localFont, localFontFamily)
                this.initFontBuffer(localFontFamily, localFontBuffer.buffer)
                this.previewFontLoading = true
            } else {
                console.debug("No local font in browser storage")
            }
        } catch (e) {
            console.error("Failed to init font from localStorage")
            console.error(e)
            this.previewFontLoading = false
        }
    },

    methods: {
        ...mapActions(useFontStore, ['clearFont']),

        /**
         * Entire window as drag and drop zone
         */
        onDrag: function (e) {
            let body = document.querySelector("body");
            e.stopPropagation();
            e.preventDefault();
            e.dataTransfer.dropEffect = "copy";
            if (body.className.indexOf(" dropzone ") === -1) {
                body.className += " dropzone ";
            }
            this.dragging = true
        },

        onDrop: function (e) {
            this.clearFont(true)
            e.stopPropagation()
            e.preventDefault()
            this.onFileInput(e)
            this.hideDragUI()
        },

        hideDragUI: function () {
            this.dragging = false;

            let body = document.querySelector("body");
            body.className = body.className.replace(" dropzone ", "");
        },


        /**
         * Load a user's local font file
         * The passed in event either originates from the file input filed and will
         * have an target.files attribute, or it originates from the drop event
         * and will have a dataTransfer.files attribute; the file handling from
         * there on out is the same
         */
        onFileInput: function (e) {
            let files = [],
                file = undefined,
                reader = new FileReader(),
                that = this;

            console.log(e)

            this.previewFontLoading = true

            if (typeof e.dataTransfer !== "undefined") {
                console.warn("drag and drop font", e.dataTransfer.files);
                files = e.dataTransfer.files;
            } else if (typeof e.target.files !== "undefined") {
                console.warn("file input font", e.target.files);
                files = e.target.files;
            }
            file = files[0];

            try {
                window.plausible("Select Font", {
                    props: {
                        event_category: "Font",
                        event_label: "Local",
                        value: file.name,
                    },
                });
            } catch (e) {
                console.error(e);
            }

            try {
                if (files.length > 1) {
                    that.showError("You can only preview one font at a time.");
                    return;
                }

                if (!file.name.match(/\.(ttf|otf)$/gim)) {
                    that.showError("Only ttf and otf files are supported.");
                    return;
                }

                if (file.size == 0) {
                    that.showError("The file appears to be empty.");
                    return;
                }

                // Hook in a listener and create a new font from the loaded ArrayBuffer
                reader.addEventListener("load", async function (/* state */) {
                    if (reader.result) {
                        
                        const family = that.$helpers.familyNameFromFileName(file.name);
                        const data = await that.$utils.bytesToBase64DataUrl(reader.result)

                        console.log("LOAD", data)

                        window.localStorage.setItem("localFont", data)
                        window.localStorage.setItem("localFontFamily", family)
                        
                        that.initFontBuffer(family, reader.result)
                    } else {
                        that.showError("Error parsing the uploaded file.");
                        that.clearFont(true)
                    }
                });

                // Trigger the actual file read
                console.log("TRIGGER LOAD")
                reader.readAsArrayBuffer(file);
            } catch (e) {
                console.error("ERROR", e);
                this.showError(e);
            }
        },

        initFontBuffer: function (family, buffer) {
            const that = this;
            
            console.log("NEW FONT", family, buffer)
            that.font = new Font(family, { buffer: buffer });

            that.font
                .load()
                .then(that.onFontLoaded, function (e) {
                    // The error handler for onFontLoaded
                    that.showError(e);
                })
                .then(function (result) {
                    console.log("onFontLoaded result", result)
                    if (!result) {
                        that.clearFont(true)
                        return;
                    }
                    // KEEP
                    // Prepend this local font to the fonts dropdown

                    // Don't add local font to dropdown
                    // Get font name, if possible
                    // let fontName = result.fontface.family;
                    // try {
                    //   fontName = result.opentype.names.fullName.en;
                    // } catch (e) {
                    //   console.warn("Font name parsing error", e);
                    // } finally {
                    //   fontName = "Local: " + fontName;
                    // }
                    // let fontObj = {
                    //   name: fontName,
                    //   files: [],
                    //   opentype: result.opentype,
                    //   fontface: result.fontface,
                    //   chars: result.chars,
                    //   display_name: result.display_name,
                    // };                
                    // that.fontList.unshift(fontObj);
                    // that.fontSelect = fontObj;
                    // that.fontDisplayName = result.display_name
                })
                .catch(function (e) {
                    console.error(e)
                    that.showError("Error parsing the uploaded file.");
                    that.clearFont(true)
                })
        },

        /**
         * The callback for when new Font() has finished loading, either from one
         * or more webfonts or from a local file buffer
         * Once a font has been loaded and its opentype font and fontface are
         * stored this function can be explicitly called to update the UI with this
         * font
         */
        onFontLoaded: function (result) {
            console.debug("onFontLoaded", result, this.font.family);
            let that = this;
            this.fontFamily = result.fontface.family;
            console.warn("CHARS", result.chars);

            this.$analytics.trackEvent("Select Font", "Font", "Local")

            let info = window.localStorage.getItem("localFontInfo")
            if (info) {
                info = JSON.parse(info)
                this.chars = info.chars
                this.supported = info.supported
                this.family = info.family
                that.hasPreviewFont = true
                that.previewFontLoading = false
            } else {
                let data = result.chars.map((val) => encodeURIComponent(val));
                fetch(process.env.VUE_APP_API_URI + "support", {
                    method: "POST",
                    body: "c=" + data.join("&c="),
                    headers: {
                        "Content-type": "application/x-www-form-urlencoded",
                    },
                })
                    .then((res) => res.json())
                    .then(function (data) {
                        console.log("SUPPORT", data);
                        
                        // TODO refactor these to persist to localStorage more elegantly
                        that.chars = result.chars
                        that.supported = data
                        that.family = result.fontface.family
    
                        that.hasPreviewFont = true
                        that.previewFontLoading = false

                        let saveInfo = {
                            chars: result.chars,
                            supported: data,
                            family: result.fontface.family
                        }
                        window.localStorage.setItem("localFontInfo", JSON.stringify(saveInfo))
                    })
                    .catch((error) => {
                        console.error(error);
                        this.$notify({
                            group: "messages",
                            type: "error",
                            title: "Server not reachable.",
                            text:
                                "Cannot process font character set support. Please try again in a moment.",
                            duration: -1,
                        });
                    });
            }


            return result;
        },

        showError: function (e) {
            this.$notify({
                group: "messages",
                type: "error",
                title: "Something went wrong loading the supplied font: " + e,
            });
            this.clearFont(true);
        },

        /**
         * Reset the UI, reset font store
         */
        removeFont: function () {
            this.font = null;
            this.fontSelect = null;
            this.clearFont(true)
        },
    },
};
</script>

<style lang="scss" scoped>
#selection {

    input {
        width: 100%;
        pointer-events: none;
    }

    #remove-font {
        position: absolute;
        top: 0;
        width: $button-height;
        height: $button-height;
        top: 2px;
        right: 2px;
        border: 0;
        background: var(--background);
        z-index: 9;
        color: var(--link);

        i {
            font-size: 24px;
        }
    }
}

#dropzone {
    border: 2px dashed var(--link);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $gutter*0.5;
    flex-direction: column;
    border-radius: $border-radius;
    position: relative;

    #dropzone-notice-on-drag {
        display: none;
    }

    &.highlight {
        border: 2px solid var(--link);

        // :deep(p) {
        //   visibility: hidden !important;
        // }
        // :deep(.multiselect) {
        //   visibility: hidden !important;
        // }

        #dropzone-notice-on-drag {
            background: var(--link-dimmed);
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            z-index: 9;
        }
    }

    button {
        color: inherit;
        text-decoration: none;
        border-bottom: 1px dashed var(--link);
    }

    p {
        margin: 0;
        text-align: center;
    }
}

input[type="file"] {
    display: none;
}
</style>