<script>
import { createApp } from "vue"
import { createRouter, createWebHistory } from "vue-router"
import FloatingVue from "floating-vue"
import "floating-vue/dist/style.css"
import Notifications from "@kyvg/vue3-notification"

import Utils from "./Utils"
import Helpers from "./Helpers"
import Texts from "./Texts"
import Analytics from "./Analytics"
import { useAppStore } from "./stores/appStore"
import { useFontStore } from "./stores/fontStore"
import { createPinia } from "pinia"

// Views
import Index from "./views/Index.vue"
import NotFound from "./views/NotFound.vue"

import Home from "./views/partials/Home"
import About from "./views/About.vue"
import Language from "./views/partials/Language.vue"

import Preview from "./components/Preview"

import preload from "./preload.json"
import UnicodeData from "./UnicodeData"

const routes = [{
  // Note this prefix so that github pages (dev) can serve this from a
  // subdirectory
  path: process.env.VUE_APP_PREFIX + '/',

  component: Index,

  // Basically all views are sub-views of the main component; let's keep it
  // this way in case we want to eventually have views which replace not just
  // one of the sides of the app screen, but the whole screen (plus e.g. 404)
  children: [
    { name: "index", path: '', component: Home },
    { name: "support", path: 'support', component: Preview },
    { name: "language", path: ':script/:iso', component: Language }
  ]
},

{
  name: "about",
  path: '/about',
  component: About,
},



// 404 catch all
{
  path: process.env.VUE_APP_PREFIX + '/:pathMatch(.*)*',
  component: NotFound,
  name: "404"
}
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.afterEach((to, from) => {
  console.debug("Routing from", from, "to", to)
  const body = document.getElementsByTagName("body")[0],
    appStore = useAppStore(),
    fontStore = useFontStore();

  // For any route have "tpl-routename" in the body class
  body.className = body.className.replace(/tpl-[a-z]+/g, "") + " tpl-" + to.name

  if (to.name === "index" || to.name === "language") {
    fontStore.clearFont()
  }

  // Make a route landing on language page load that language
  if (to.name === "language") {
    appStore.selectedLanguages = [Utils.capitalize(to.params.script + '_' + to.params.iso)]
    appStore.dbMode = true
  }

  if (to.name === "index") {
    appStore.dbMode = true
    appStore.selectedLanguages = []
  }

  if (to.name === "support") {
    appStore.dbMode = false
    appStore.selectedLanguages = []
  }


})

const pinia = createPinia()

const app = createApp({
  data: function () {
    return {
      hasSeenIntro: false,
      hasExitedIntro: false,
      // Setting this to true from anywhere in the app will re-open the intro
      openIntro: false,

      hasSeenCta: false,
    }
  },

  /**
   * Keep track if a user has seen the intro already, and expose this to all
   * components as property of $root
   */
  mounted: function () {
    let cookies = {};
    const that = this,
      cookiestrings = document.cookie.split("; ");

    // If showing the intro, listen for click anywhere which hides it
    // Since the intro can be re-shown we need this listener either way
    document.getElementsByTagName("body")[0].addEventListener("click", function (e) {
      if (e.target.className.indexOf("reset-intro") === -1 && !that.hasExitedIntro) {
        that.hasExitedIntro = true
      }
    })

    for (let str of cookiestrings) {
      const parts = str.split("=")
      cookies[parts[0]] = parts[1]
    }

    if ("intro" in cookies) {
      this.hasSeenIntro = true
      this.hasExitedIntro = true
    } else {
      // Don't show intro for a week
      document.cookie = "intro=1;path=/;samesite=strict;max-age=" + 60 * 60 * 24 * 7
      this.hasSeenIntro = false
    }

    // Preload most common characters
    UnicodeData.forChars(preload)
  }
})

// Mount all helpers and utils as vue plugins

const inlineTextsPlugin = {
  install (app) {
    app.config.globalProperties.$texts = Texts
  }
}
app.use(inlineTextsPlugin)

const inlineHelpersPlugin = {
  install (app) {
    app.config.globalProperties.$helpers = Helpers
  }
}
app.use(inlineHelpersPlugin)

const inlineUtilsPlugin = {
  install (app) {
    app.config.globalProperties.$utils = Utils
  }
}
app.use(inlineUtilsPlugin)

const inlineAnalyticsPlugin = {
  install (app) {
    app.config.globalProperties.$analytics = Analytics
  }
}
app.use(inlineAnalyticsPlugin)

app.use(FloatingVue)
app.use(Notifications)
app.use(router)
app.use(pinia)
app.mount("#app")

// No effect, not sure how to allow nobr without warnings in _dev_ :/
app.config.compilerOptions.isCustomElement = tag => tag.startsWith('nobr')

// Purge localStorage on version jumps
try {
  let storageVersion = localStorage.getItem("version")
  if (!(storageVersion) || parseInt(storageVersion) < process.env.VUE_APP_VERSION) {
    localStorage.removeItem("languageData")
    localStorage.removeItem("localFont")
    localStorage.removeItem("localFontFamily")
    localStorage.removeItem("localFontInfo")
    localStorage.setItem("version", process.env.VUE_APP_VERSION)
  }
} catch (e) {
  console.error("Failed to invalidate localStorage cache:", e)
}

console.debug(process.env)

export default { app }
</script>