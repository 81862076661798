<template>
  <div id="mode-switcher" :class="{ 'db-mode': dbMode, 'font-mode': !dbMode }">
    <label class="checkbox-huge-toggle" v-on:click="onClick">
      <input type="checkbox" v-model="dbMode" v-on:change="switchMode">
      <span :class="{ 'current': dbMode }">Database view</span>
      <span :class="{ 'current': !dbMode }">Font checker</span>
    </label>
  </div>
</template>

<script>
import { useAppStore } from "../stores/appStore"
import { mapWritableState } from "pinia"

export default {
  name: "ModeSwitcher",

  computed: {
    ...mapWritableState(useAppStore, ['dbMode']),
  },

  methods: {
    switchMode: function () {
      if (this.dbMode) {
        this.$router.push({ name: "index" })
      } else {
        this.$router.push({ name: "support" })
      }
    },

    /**
     * Ignore clicks when clicking on the currently selected mode as this would
     * only toggle again, which is unintuitive (because they are both nested in
     * the <label>)
     * 
     * @param {*} e 
     */
    onClick: function (e) {
      if (e.target.className.indexOf("current") !== -1) {
        e.preventDefault()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#mode-switcher {
  margin-bottom: $gutter*0.5;
  user-select: none;

  &.db-mode {
    span:nth-child(2):hover {
      background: var(--link);
      color: var(--background);
    }
  }

  &.font-mode {
    span:nth-child(3):hover {
      background: var(--link);
      color: var(--background);
      border-right-color: var(--link);
    }
  }
}
</style>