<template>
    <div id="home">
        <header class="invert">
            <div>
                <h1>Briefly about Hyperglot</h1>
                <p>Hyperglot systematizes the basic characters needed to write in the world’s languages.
                    The <i>Database view</i> lets you browse through all of the languages and their orthographies in the
                    world’s scripts. The <i>Font checker</i> detects which languages a font might support. You can also
                    review any additional design requirements. The Hyperglot database currently includes orthographies for
                    over <nobr><span class="tooltip-link"
                            v-tooltip="$texts.tooltip('tooltip_language_count_homepage')">{{ numLanguages }}
                            languages</span></nobr>
                    (ca <span class="tooltip-link" v-tooltip="$texts.tooltip('tooltip_speaker_count_homepage')">{{ numSpeakers }}
                        speakers</span>). <strong>Start by selecting a language or font on the
                        left.</strong>
                </p>
                <router-link class="button-filled" :to="{ name: 'about' }">More about Hyperglot</router-link>
                <a class="button-outline reset-intro" v-on:click="resetTutorial()">show tips</a>
            </div>
        </header>

        <footer id="homepage-footer">
            <div id="footer-opensource">
                <div>
                    <h1>Open source project</h1>
                    <p>This Hyperglot web app is based on an open-source toolkit: a command-line tool, Python package, and
                        database. Please, help us to make it better. If you spot an issue or notice your favourite language
                        is altogether missing from the database, get in touch. We will happily incorporate your feedback and
                        credit you.</p>
                </div>

                <a class="button-filled" href="https://github.com/rosettatype/hyperglot/">Hyperglot on Github</a>
                <a class="button-outline" href="mailto:info@rosettatype.com?subject=Hyperglot website feedback">Send
                    feedback</a>
            </div>

            <Imprint class="hide-on-mobile " />
        </footer>
    </div>
</template>

<script>
import Hyperglot from "../../hyperglot.json"
import Imprint from "../../components/Imprint"

export default {

    name: "Home",

    components: { Imprint },

    mounted: function () {
        try {
            document.getElementById("right").scrollTop = 0
        } catch {
            // pass
        }
    },

    computed: {
        numLanguages: function () {
            let languages = [];

            languages = this.$helpers.languagesToList(Hyperglot)

            // Count only iso, not script + iso (since that would give duplicates)
            languages = languages.map((scriptIso) => {
                return scriptIso.split("_")[1]
            });

            return this.$utils.arrayUnique(languages).length;
        },

        numSpeakers: function () {
            let speakers = 0,
                counted = [];

            // Speaker counts are per language, not per orthography/script, so count
            // per ISO, not per scriptIso

            // The full DB data indexed by script & iso
            for (let script in Hyperglot) {
                for (let iso in Hyperglot[script]) {
                    if (counted.indexOf(iso) === -1) {
                        speakers += Hyperglot[script][iso].s
                        counted.push(iso)
                    }
                }
            }

            return this.$helpers.formattedSpeakers(speakers, false)
        },
    },

    methods: {
        resetTutorial: function () {
            console.log("reset", this.$root)
            // this.$root.hasExitedIntro = false
            // this.$root.hasSeenIntro = false
            this.$root.openIntro = true
        }
    },
}
</script>

<style lang="scss" scoped>
#home header {
    @include gutter(0, -1, "margin");
    @include gutter(0, 1, "padding");

    >div+div {
        margin-top: $gutter;
    }

    p {
        margin-bottom: 1em;
    }
}

.button-filled,
.button-outline {
    margin-right: 0.75rem;
    margin-bottom: 0.75rem !important;

    +a {
        margin-top: 0;
    }
}

.reset-intro {
    cursor: pointer;
    text-decoration: underline;
}</style>