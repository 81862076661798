<template>
    <div class="char" :class="{
        aux: aux,
        mark: mark,
        highlighted: highlighted,
        'not-supported': !supported,
        'positional-form': positional !== false,
    }" :style="{ 'font-family': family }" v-on:mouseover="zoomIn()" v-on:mouseleave="zoomOut()"
        v-on:click="copyChar($event)">
        <span :dir="dir" v-html="charMarkup"></span>
        <small>{{ unicodeDisplaySmall }}</small>

        <i class="focus" v-on:click="searchForChar(char)" v-tooltip="$texts.tooltip('tooltip_character_search')"></i>
        <i v-if="alternate" class="alternate" v-on:click="scrollToDesignRequirements(alternateScriptIsos)"
            :data-script-isos="alternateScriptIsos.join(',')" v-tooltip="$texts.tooltip('tooltip_character_info')"></i>

        <div ref="characterPreview" class="character-preview" :class="{ preview: preview, below: below }">
            <span :dir="dir">
                <span v-if="alternate" class="no-locl" lang="" v-html="charMarkup"></span>
                <span v-html="charMarkup"></span>
            </span>

            <small class="top"><span>{{ name }}</span><span>{{ unicodeDisplay }}</span></small>
            <small class="right">{{ direction }}</small>
            <small class="bottom">
                <span v-if="!alternate">No specific design requirements</span>
                <span v-else>See specific design requirements below</span>
            </small>
            <small class="left">{{ description }}</small>
        </div>
    </div>
</template>

<script>
import { useAppStore } from "../stores/appStore"
import { useFontStore } from "../stores/fontStore"
import { mapWritableState, mapState } from "pinia"

export default {
    name: "Character",

    props: [
        "char",
        "alternate",
        "alternateScriptIsos",
        "scriptIsos",
        "aux",
        "mark",
        "unicodedata",
        "supported",
    ],

    data: function () {
        return {
            preview: false,
            below: false,
        };
    },

    computed: {
        ...mapWritableState(useAppStore, [
            'search',
            'highlightedLanguages',
            'highlightSingleCharacter',
        ]),

        ...mapState(useFontStore, [
            'family',
            'chars',
            'hasPreviewFont',
        ]),

        /**
         * Output the char, and wrap with dottedCircle for marks
         */
        charMarkup: function () {
            if (!this.mark) {
                if (this.positional === false) {
                    return this.char
                } else {
                    return "<span class='" + (this.hasPreviewFont ? this.family : "noto-sans") + " " + this.positional + "'>" + this.char + "</span>"
                }
            }
            // No need for this: The dir="rtl" should take care of glyph order!
            // if (this.rtl) {
            //     return this.char + "◌"
            // } else {
            return "◌" + this.char
            // }
        },

        name: function () {
            return this.unicodedata !== undefined ? this.unicodedata[0] : "";
        },

        description: function () {
            if (this.unicodedata === undefined) {
                return ""
            } else {
                return this.unicodedata[1]
            }
        },

        direction: function () {
            if (this.unicodedata === undefined) {
                return ""
            } else if (this.unicodedata[2] === "Arabic Letter") {
                return "Right to left"
            } else {
                return this.unicodedata[2]
            }
        },

        dir: function () {
            // A bit crude, but marks will not have their RTL in the BIDI because
            // they are "Non Space Mark", not "Right To Left"
            if (this.name) {
                const lc_name = this.name.toLowerCase(),
                    lc_desc = this.description.toLowerCase();

                if (lc_name.indexOf("arabic") !== -1 || lc_name.indexOf("hebrew") !== -1 || lc_desc.indexOf("arabic") !== -1 || lc_desc.indexOf("hebrew") !== -1) {
                    return "rtl";
                }
            }
            return "ltr"
        },

        positional: function () {
            // Crudely check for ZWJ to detect positional forms.
            // Note that the string sequence from the API is interpreted in RTL
            // order, so starting with ZWJ at the start means glyph followed
            // by ZWJ.

            if (this.char.trim() === "\u200D" || this.char.length === 1) {
                // If this char _is_ ZWJ as part of a charset it is not a
                // positional
                return false;
            }

            const ZWJ_init = this.char.match(/\u200D$/),
                ZWJ_fina = this.char.match(/^\u200D/);

            if (ZWJ_init && ZWJ_fina) {
                return "medi"
            } else if (ZWJ_init && !ZWJ_fina) {
                return "init"
            } else if (!ZWJ_init && ZWJ_fina) {
                return "fina"
            } else {
                return false
            }
        },

        highlighted: function () {
            // Do not highlight if another character is being hovered, only highlight
            // when implicitly highlighted by language name hovering.
            if (this.highlightSingleCharacter) {
                return false
            }

            for (let scriptIso of this.scriptIsos) {
                if (this.highlightedLanguages.indexOf(scriptIso) !== -1) {
                    return true
                }
            }
            return false
        },

        unicodeDisplay: function () {
            if (this.positional !== false) {
                let without_zwj = this.char.replaceAll("\u200D", "");
                return "Positional form of " + this.$helpers.unicodeShortId(without_zwj)
            }
            return this.$helpers.unicodeShortId(this.char)
        },

        unicodeDisplaySmall: function () {
            // Single unicodes 8bit U+XXXX or 16bit U+XXXXXX

            if (this.positional) {
                // TMP for debugging
                // return this.$helpers.unicodeShortId(this.char)
                return ""
            }

            if (this.unicodeDisplay.length > 7) {
                return "multiple"
            }
            return this.unicodeDisplay
        },
    },

    methods: {
        zoomOut: function () {
            this.highlightedLanguages = []
            this.highlightSingleCharacter = false
            this.preview = false;
        },

        zoomIn: function () {
            this.highlightedLanguages = this.scriptIsos
            this.highlightSingleCharacter = true

            if (this.preview) {
                // Don't reposition if aleady open
                return;
            }

            let prev = this.$refs.characterPreview;
            // Reset first, then compare
            prev.style.transform = "translateX(0)";

            let pos = prev.getBoundingClientRect(),
                ul = this.$el.parentNode.parentNode.getBoundingClientRect(),
                li = this.$el.parentNode.getBoundingClientRect();

            if (Math.round(pos.left) < Math.round(ul.left)) {
                // align left border
                // console.debug("align preview left");
                prev.style.transform = "translateX(" + (ul.left - pos.left) + "px)";
            }

            if (Math.round(pos.right) > Math.round(ul.right)) {
                // align right border
                // console.debug("align preview right");
                prev.style.transform = "translateX(" + (ul.right - pos.right - 1) + "px)";
            }

            // open to below when near the window top edge
            this.below = li.top - 50 < pos.height;

            this.preview = true;
        },

        copyChar: function (e) {
            const that = this;

            // Don't trigger copy if the small interaction icons were clicked
            if (e.target.nodeName.toLowerCase() === "i") {
                e.preventDefault()
                return;
            }

            try {

                navigator.clipboard.writeText(this.char).then(
                    // success
                    () => {
                        that.$notify({
                            group: "messages",
                            type: "success",
                            title: "Copied '" + that.char + "' to clipboard.",
                        });
                    },
                    // failure
                    () => {
                        that.$notify({
                            group: "messages",
                            type: "error",
                            title: "There was a problem copying the character.",
                        });
                    }
                );
            } catch {
                //
            }
        },

        searchForChar: function (char) {
            // In case this is an unencoded base + mark combination search for
            // the base only
            this.search = char.replaceAll("\u200D", "")[0]

            try {
                document.getElementById("left").scrollTop = 0
            } catch (e) {
                console.warn(e)
            }
        },

        scrollToDesignRequirements: function (scriptIsos) {
            // Let's just attempt to scroll
            const requirements = document.querySelectorAll(".design-requirements-section")

            if (!requirements) {
                return
            }

            try {
                const req = document.querySelectorAll("[data-language-names]");
                let bestMatchLength = 0,
                    bestMatch = undefined;

                // Look at all requirements blocks and what scriptIsos they
                // belong to; since requirements and alternates are not mapped
                // 1:1 for now we try scroll to the block that has the best
                // language overlap with the alternate's scriptIsos
                for (let block of req) {
                    let blockScriptIsos = block.dataset.languageNames.split(","),
                        shared = this.$utils.arrayIntersect(blockScriptIsos, scriptIsos);

                    if (shared.length === 0) {
                        continue
                    }

                    if (typeof (bestMatch) === "undefined" || shared.length > bestMatchLength) {
                        bestMatch = block
                        bestMatchLength = shared.length
                    }
                }

                bestMatch.scrollIntoView()
            } catch (e) {
                console.warn(e)
                // Couldn't quite figure out where to scroll _exactly_ so scroll
                // down to the section beginning
                requirements[0].scrollIntoView()
            }
        }
    },
}
</script>

<style lang="scss" scoped>
$size: 5vw;

// The main container in the grid
.char {
    overflow: visible;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: inherit;

    transition: background 0.2s ease-out;

    &.highlighted {
        background: $highlight-muted;
    }

    &.positional-form {
        background: #{$link}22;
    }

    &.not-supported {
        >span {
            color: #00000055;
        }

        &:hover {
            background: #AAAAAA !important;
            color: $white;

            >span {
                color: $white;
            }
        }

        .character-preview {
            background: #AAAAAA;
            color: $white;
        }
    }

    // The character itself in the grid view
    >span {
        font-size: #{"calc(" $size*0.4 ")"};
        position: absolute;
        top: 0.125em;
        line-height: 2em;
        // outline: 1px solid red;

        @include mobile {
            font-size: 4vw;
        }
    }

    // The unicode short identifier and the AUX label
    // &.aux:before,
    // &.mark:before {
    >small {
        @include font-bold;
        font-size: 7px;
        font-feature-settings: "tnum" 1;
        position: absolute;
        bottom: 0.1em;
    }

    >i {
        position: absolute;
        top: -2px;
        font-size: 12px;
        cursor: pointer;

        &:hover {
            color: $link;
        }
    }

    >i.alternate {
        @extend .icon-tiny-info;
        right: 6px;
    }

    >i.focus {
        @extend .icon-tiny-search;
        left: 6px;
        display: none;
    }

    &:hover>i.focus {
        display: block;
    }

    &.not-supported:after {
        background: #00000055;
    }
}

.character-preview {
    $s: 40vmin;
    position: absolute;
    height: $s;
    width: $s;
    top: calc(-#{$s} - 1px);
    left: -20vmin;
    margin-left: 50%; // half the charset square
    color: $light;
    z-index: 199;

    pointer-events: none;

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0.5rem 1rem;

    background: $highlight;
    color: $black;

    // opacity: 0;
    visibility: hidden;

    &.preview {
        // opacity: 1;
        visibility: visible;
    }

    &.below {
        top: auto;
        bottom: calc(-#{$s} - 1px);
    }

    // .supported & {
    //   background: $black;
    // }

    // The letter
    >span {
        $top: 0.15em;

        display: block;
        font-size: $s*0.35;
        line-height: 2em;
        margin-top: auto;
        margin-bottom: auto;
        flex-grow: 0;
        // outline: 1px solid red;

        .no-locl {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            display: block;
            text-align: center;
            opacity: 0.3;
        }
    }

    // The descriptions
    small {
        @include font-bold;
        display: block;
        text-align: center;
        font-feature-settings: "tnum" 1;
        font-size: 14px;
        letter-spacing: 0.06em;
        flex-grow: 1;
        position: absolute;

        $offset: 10px;

        span {
            display: block;
        }

        span+span {
            margin-top: 0.75em;
        }

        &.top,
        &.bottom {
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            padding: 0 $offset;

        }

        &.top {
            top: $offset;
        }

        &.right {
            top: 50%;
            right: $offset;
            transform: rotate(90deg) translateX(50%);
            transform-origin: right top;
        }

        &.bottom {
            bottom: $offset;
        }

        &.left {
            right: calc(100% - #{$offset});
            top: 100%;
            transform: rotate(-90deg) translateX(100%);
            transform-origin: right top;
            width: 100%;
        }
    }

    p,
    small {
        line-height: 1.2;
    }

    @include mobile {
        width: 75vw;
        height: 75vw;

        >span {
            font-size: 20vw;
        }
    }
}
</style>