<template>
  <div id="language">
    <header>
      <div class="header-buttons action-wrapper">
        <button class="button-outline" v-on:click="copyLinkToClipboard()" v-if="!loading">Copy link</button>
        <CopyToClipboard class="button-clipboard" v-if="!loading" :base="combinedBase ? combinedBase : []"
          :aux="combinedAux ? combinedAux : []" :mark="combinedMark ? combinedMark : []"
          :show_option_language_names="false" :show_option_characters="false">
        </CopyToClipboard>
      </div>
      <h1 id="language-name">{{ name }} ({{ iso }})</h1>
      <p>{{ speakers }} speakers</p>
      <small>{{ note }}</small>
    </header>
    <Modal ref="linkModal" :class="{ 'minimal': linkCopiedMessage !== '' }">
      <div>
        <p>{{ linkCopiedMessage }}</p>
      </div>
    </Modal>

    <div v-if="!loading">
      <Orthography class="orthography" v-for="(o, id) in orthographies" :key="id" :name="name" :autonym="o.aut"
        :base="o.bas" :aux="o.aux" :marks="o.mar" :script="o.scr" :status="o.sta" :note="o.not" :speakers="speakers"
        :requirements="o.req" :validity="validity" :alternates="orthographyAlternates(o.alt, o.scr + '_' + iso)"
        :alternateScriptIsos="[o.scr + '_' + iso]" :lang="lang"></Orthography>
    </div>

    <div v-else>
      <Orthography class="orthography" v-for="(o, id) in data.o" :key="id" :name="name" :script="o.sc" :autonym="o.a"
        :status="unshortenOrthography(o.st)" :speakers="speakers" :baseCount="o.b_c" :auxCount="o.a_c" :markCount="o.m_c"
        :validity="unshorted_language(data.v)" :base="[]" :aux="[]" :marks="[]"></Orthography>
    </div>

    <footer id="page-footer">
      <Imprint />
    </footer>

    <div class="loading-cover loading" v-if="loading">
      <div class="loading-spinner-large"></div>
      <span class="loading-message">{{ $texts.t.loading }}</span>
    </div>
  </div>
</template>

<script>
import Hyperglot from "../../hyperglot.json"

import { mapState, mapActions } from "pinia"
import { useAppStore } from "../../stores/appStore"
import { useLanguageStore } from "../../stores/languageStore"

import Modal from "../../components/Modal"
import Orthography from "../../components/Orthography"
import Imprint from "../../components/Imprint"
import CopyToClipboard from "../../components/CopyToClipboard"

export default {
  name: "Language",

  components: {
    Modal,
    Orthography,
    Imprint,
    CopyToClipboard,
  },

  data: function () {
    return {
      // The unfiltered returned orthographies
      orthographies_unfiltered: [],
      missing: [],
      loading: true,
      history: window.history,
      linkCopiedMessage: "",
    };
  },

  updated: function () {
    document.getElementById("right").scrollTop = 0
  },

  computed: {
    ...mapState(useLanguageStore, ['languageData']),
    ...mapState(useAppStore, ['selectedLanguages', 'includeSecondaryAndDeprecated', 'includeDraft']),

    data: function () {
      return Hyperglot[this.script][this.iso]
    },

    script: function () {
      const titlecase = this.$utils.capitalize(decodeURI(this.$route.params.script)),
        hgScripts = Object.keys(Hyperglot);

      if (titlecase in Hyperglot) {
        return titlecase
      }

      for (let i = 0; i < hgScripts.length; i++) {
        const scriptLowercase = hgScripts[i].toLowerCase();
        if (scriptLowercase === titlecase.toLowerCase()) {
          return hgScripts[i]
        }
      }

      throw Error("Script not found for: ", this.$route.params.script)
    },

    iso: function () {
      return this.$route.params.iso
    },

    scriptIso: function () {
      return this.script + "_" + this.iso
    },

    name: function () {
      console.log(Object.keys(Hyperglot))
      return this.data.n
    },

    autonym: function () {
      return this.data.a
    },

    speakers: function () {
      return this.$helpers.formattedSpeakers(this.data.s)
    },

    note: function () {
      return "note" in this.data ? this.data.note : ""
    },

    lang: function () {
      return "l" in this.data ? this.data.l : this.iso
    },

    /**
     * Filter _orthographies against the filters
     */
    orthographies: function () {
      const that = this;

      if (!this.orthographies_unfiltered.length) {
        return []
      }

      return this.orthographies_unfiltered.filter((o) => {
        // If viewing a language in a script that has only non-primary orthographies
        // for the script of this URL then show it, even if not included by filter
        if (this.script === o.scr) {
          return true
        }

        if (!that.includeSecondaryAndDeprecated && o.sta !== "primary") {
          return false
        }
        return true
      })
    },

    /**
     * Combined characters for copying; for now used first orthography
     */
    combinedBase: function () {
      if (this.orthographies.length > 0) {
        return this.orthographies[0].bas
      }
      return []
    },

    combinedAux: function () {
      if (this.orthographies.length > 0) {
        return this.orthographies[0].aux
      }
      return []
    },

    combinedMark: function () {
      if (this.orthographies.length > 0) {
        return this.orthographies[0].mar
      }
      return []
    },
  },

  methods: {
    ...mapActions(useLanguageStore, ['fetchLanguageData']),

    showLanguage: async function () {
      const that = this

      try {
        document.getElementById("right").scrollTop = 0
      } catch (e) {
        // pass
      }

      this.loading = true;
      this.orthographies_unfiltered = []

      this.fetchLanguageData([this.iso]).then((res) => {
        if (!(that.iso in res)) {
          return
        }

        console.log("DATA", res)

        that.orthographies_unfiltered = res[this.iso].ort
        that.validity = res[this.iso].validity

        that.loading = false;
      })
    },

    copyLinkToClipboard: function () {
      const that = this;

      that.$refs.linkModal.open()

      try {
        navigator.clipboard.writeText(window.location.href).then(
          // success
          () => {
            that.linkCopiedMessage = "Link copied to clipboard."
          },
          // failure
          () => {
            that.linkCopiedMessage = "Failed to copy link. Please just copy the URL from your browser."
          }
        );
      } catch (e) {
        that.linkCopiedMessage = "Failed to copy link. Please just copy the URL from your browser."
      }

      setTimeout(function () {
        if (that.linkCopiedMessage !== "") {
          that.$refs.linkModal.close()
        }
      }, 2500)

      this.$analytics.trackEvent("UI", "Copy link", "Copied", window.location.href)
    },

    unshortenOrthography: function (input) {
      switch (input) {
        case "p":
          return "Primary"
        case "s":
          return "Secondary"
        case "h":
          return "Historical"
        case "l":
          return "Local"
        case "d":
          return "Deprecated"
      }
    },

    unshorted_language: function (input) {
      switch (input) {
        case "d":
          return "Draft"
        case "t":
          return "Todo"
        case "p":
          return "Preliminary"
        case "v":
          return "Verified"
      }
    },

    /**
     * For a list of characters return a dict indexed by chars with [scriptIso] values
     * 
     * @param {*} chars 
     * @param {*} scriptIso 
     */
    orthographyAlternates: function (chars, scriptIso) {
      let alts = {};
      if (!chars || chars.length === 0) {
        return alts;
      }

      console.error("orthographyAlternates", chars, scriptIso)
      for (const char of chars) {
        alts[char] = [scriptIso]
      }
      return alts;
    }
  },

  watch: {
    "selectedLanguages": {
      deep: true,
      immediate: true,
      handler(val, old) {
        console.log("selectedLanguages", val, old)

        this.showLanguage(val)
      }
    }
  },
};
</script>

<style lang="scss" scoped>
#language {
  min-height: 100vh;
}

header {
  margin-bottom: $gutter;
}

.action-wrapper {
  display: flex;

  .button-outline {
    margin-top: 0;
    margin-bottom: 0;
  }

  .button-clipboard {
    margin-top: 0;
    margin-left: 10px !important;
  }
}

.orthography+.orthography {
  margin-top: 10rem;
}

.loading-cover {
  display: block;
  background: var(--background-transluscent);

  .loading-message,
  .loading-spinner-large {
    top: 40vh;
  }
}
</style>