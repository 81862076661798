<template>
    <ul class="charset character-list placeholder">
        <li v-for="n in count" :key="n">
            <Character :char="' '" :aux="false" :mark="false" :scriptIsos="[]" />
        </li>
    </ul>
</template>
<script>
import Character from "./Character"

export default {
    name: "CharacterListPlaceholder",

    components: {
        Character,
    },

    props: ["count"],
}
</script>