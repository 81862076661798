<template>
    <div>
        <button class="button-outline copy-open" v-on:click="open">Copy data</button>

        <!-- The dialog for selecting what to copy -->
        <Modal ref="modal" :class="{ 'minimal': resultMessage !== '' }">
            <div v-if="!resultMessage">
                <h1 class="h3">Copy data</h1>
                <ul class="radio-list">
                    <li v-if="show_option_language_names">
                        <label class="radio">
                            <input type="radio" name="copy" value="languageNames" checked v-model="selection">
                            <span>Language names</span>
                        </label>
                    </li>
                    <li>
                        <label class="radio">
                            <input type="radio" name="copy" value="language_details" v-model="selection">
                            <span>Language details</span>
                        </label>
                    </li>
                    <li v-if="show_option_characters">
                        <label class="radio">
                            <input type="radio" name="copy" value="characters" v-model="selection">
                            <span>Characters</span>
                        </label>
                    </li>
                    <li>
                        <label class="radio">
                            <input type="radio" name="copy" value="report" v-model="selection">
                            <span>Full report</span>
                        </label>
                    </li>
                </ul>
                <fieldset>
                    <textarea v-model="content" :class="{ 'preformatted': format === 'yaml' }"></textarea>

                    <Multiselect v-model="format" :options="formatList" :searchable="false" :close-on-select="true"
                        :allow-empty="false" track-by="name" label="name" open-direction="bottom" />
                </fieldset>

                <button id="copy" class="button-filled" v-on:click="copyToClipboard">Copy to clipboard</button>
            </div>

            <div v-else>
                <p>{{ resultMessage }}</p>
            </div>
        </Modal>
    </div>
</template>

<script>
import Multiselect from "../../node_modules/vue-multiselect"
import Helpers from "../Helpers"

import { useAppStore } from "../stores/appStore"
import { useLanguageStore } from "../stores/languageStore"
import { mapState } from "pinia"
import YAML from "yaml"

import Modal from "./Modal"

export default {
    name: "CopyToClipboard",

    components: {
        Modal,
        Multiselect,
    },

    props: ['base', 'aux', 'mark', 'show_copy_modal', 'show_option_language_names', 'show_option_characters'],

    data: () => {
        return {
            dropdownShown: false,
            selection: "languageNames",
            resultMessage: "",
            formatList: [
                {
                    name: "Format: yaml",
                    value: "yaml",
                },
                {
                    name: "Format: json",
                    value: "json",
                },
                {
                    name: "Format: text",
                    value: "text",
                },
            ],
            format: {
                name: "Format: yaml",
                value: "yaml",
            },
        }
    },

    created: function () {
        if (!this.show_option_language_names) {
            this.selection = "language_details"
        }
    },

    computed: {
        ...mapState(useAppStore, ['selectedLanguages']),

        ...mapState(useLanguageStore, ['languageData']),

        /**
         * Get the select languages' full data (retrieved from the API and with
         * expanded attributes)
         */
        selectedLanguagesData: function () {
            let data = {};

            console.log("selected languages", this.selectedLanguages)

            for (let scriptIso of this.selectedLanguages) {
                const parts = scriptIso.split("_"),
                    iso = parts[1],
                    lang = {
                        "ISO code": iso
                    };

                // unshorten orthography keys and values
                lang["orthographies"] = []

                const mapping = {
                    "scr": ["script"],
                    "aut": ["autonym"],
                    "bas": ["base", function (items) { return items.join(" ") }],
                    "aux": ["auxiliary", function (items) { return items.join(" ") }],
                    "mar": ["marks", function (items) { return items.join(" ") }],
                    "sta": ["status"],
                    "req": ["design requirements", function (items) { return items.join("\n") }],
                    "alt": ["design alternates", function (items) { return items.join("\n") }],
                }

                if ("ort" in this.languageData[iso]) {
                    for (let orthography of this.languageData[iso]["ort"]) {
                        let ort = {};

                        for (let key in mapping) {
                            if (key in orthography) {
                                const expanded = mapping[key][0],
                                    transform = mapping[key][1];

                                if (!transform) {
                                    ort[expanded] = orthography[key]
                                } else {
                                    ort[expanded] = transform(orthography[key])
                                }
                            }

                            if ("aut" in orthography) {
                                lang["autonym"] = orthography["aut"]
                            }
                        }
                        lang["orthographies"].push(ort)
                    }
                }

                // Add selective data from the raw API response
                for (let key in this.languageData[iso]) {
                    if (["includes", "ort", "preferred_as_individual"].indexOf(key) !== -1 || !this.languageData[iso][key]) {
                        continue
                    }

                    let newkey = key !== "sta" ? key : "status"

                    lang[newkey] = this.languageData[iso][key];
                }

                data[iso] = lang
            }

            return data
        },

        content: function () {
            let data;

            switch (this.selection) {
                case "languageNames":
                    data = [];

                    for (let scriptIso of this.selectedLanguages) {
                        data.push(Helpers.languageName(scriptIso))
                    }
                    data = this.$utils.arrayUnique(data).sort()

                    break;

                case "language_details":
                    data = [];

                    for (let iso in this.selectedLanguagesData) {
                        let language = Object.assign({}, this.selectedLanguagesData[iso]);

                        for (let key of ["orthographies", "speakers", "note", "validity", "status", "pre"]) {
                            delete language[key]
                        }

                        data.push(language)
                    }

                    break;

                case "characters":
                    data = {};

                    data["Base chacters"] = this.base

                    if (this.aux.length > 0) {
                        data["Auxiliary characters"] = this.aux
                    }

                    if (this.mark.length > 0) {
                        data["Marks"] = this.mark
                    }

                    if (this.format.value === "text") {
                        for (let key in data) {
                            data[key] = data[key].join(" ")
                        }
                    }

                    break;

                case "report":
                    data = [];

                    for (let iso in this.selectedLanguagesData) {
                        data.push(this.selectedLanguagesData[iso])
                    }

                    break;

            }

            return this.formatter(data, this.format.value)
        }
    },

    methods: {
        open: function () {
            this.resultMessage = ""
            this.$refs.modal.open()
            this.$analytics.trackEvent("UI", "Copy data", "Opened", "")
        },

        formatter: function (content, format) {
            const that = this;

            switch (format) {
                case "yaml":
                    return YAML.stringify(content)

                case "json":
                    return JSON.stringify(content, null, "  ")

                case "text":
                    if (Array.isArray(content)) {
                        return content.map((c) => {
                            return that.formatText(c)
                        }).join("\n\n")
                    } else {
                        return that.formatText(content)
                    }
            }
            return content
        },

        formatText: function (content) {
            return YAML.stringify(content).replaceAll("-", "\n").trim()
        },

        /**
         * Generic clipboard copying
         */
        copyToClipboard: function () {
            const that = this;

            this.$analytics.trackEvent("UI", "Copy data", "Copied", this.selection + " - " + this.format.value)

            try {
                navigator.clipboard.writeText(this.content).then(
                    // success
                    () => {
                        that.resultMessage = "Data copied to clipboard."
                    },
                    // failure
                    () => {
                        that.resultMessage = "Failed to copy data. Please try again and manually copy the data from the textarea."
                    }
                );
            } catch (e) {
                that.resultMessage = "Failed to copy data. Please try again and manually copy the data from the textarea."
            }

            setTimeout(function () {
                if (that.resultMessage !== "") {
                    that.$refs.modal.close()
                }
            }, 2500)
        },
    }
}
</script>

<style lang="scss" scoped>
.copy-open {
    margin: 0 !important;
}

:deep(.minimal .modal-content) {
    @include modal-content-minimal()

    .modal-close {
        display: none;
    }
}

ul.radio-list {
    list-style: none;
    padding: 0;
    margin-top: 1.5em;

    li {
        padding-left: 2rem;

        +li {
            margin-top: 2em;
        }
    }

    label.radio {
        margin-left: -2rem;
        color: $black !important;
        display: flex;
        align-items: baseline;

        input[type=radio]+span {
            @include font(800);
        }
    }

    label.radio input[type=radio]+span:before {
        background: $link !important;
        top: 2px !important;
    }

    label.radio input[type=radio]+span:after {
        display: none;
    }

    label.radio input[type=radio]:checked+span:after {
        background: $white;
        top: 7.5px !important;
        display: block !important;
    }
}

// The textarea and dropdown
fieldset {
    position: relative;
    margin-top: 1.5rem;

    select {
        position: absolute;
        right: 1rem;
        top: 1rem;
        outline: 1px solid $link;
    }

    :deep(.multiselect) {
        position: absolute !important;
        right: 1rem !important;
        top: 1rem !important;
        width: 15rem !important;
        margin: 0 !important;
        color: $link !important;
        background: $black !important;
        border-color: $link !important;

        .multiselect__select:before {
            color: $link !important;
        }

        .multiselect__tags {
            background: transparent !important;
        }

        .multiselect__content-wrapper {
            border-color: $link !important;
        }

        .multiselect__option {
            background: $black !important;
            color: $link !important;

            &.multiselect__option--selected,
            &.multiselect__option--highlight {
                background: $link !important;
                color: $black !important;
                @include font(800);
            }
        }
    }

    // The preview
    textarea {
        @include font-text;
        font-size: $font-size-s;
        line-height: 1.2em;
        display: block;
        border: none;
        border-radius: 2px;
        background: $black;
        width: 100%;
        min-height: 33vh;
        color: $white;
        padding: 1rem;

        &.preformatted {
            overflow-x: scroll;
            white-space: pre;
        }
    }
}

#copy {
    background: $link;
    color: $white;
    border-color: $link;

    &:hover,
    &:focus {
        background: var(--link-hover-background) !important;
        border-color: var(--link-hover-background) !important;
        color: var(--link-hover-text) !important;
    }
}
</style>