import { defineStore } from "pinia"
import Utils from "../Utils"
import Helpers from "../Helpers"

export const useAppStore = defineStore('appState', {
    // Persist the app state store in the browser windows (between reloads) but 
    // clear on window close
    // plugins: [createPersistedState({
    //   storage: window.sessionStorage,
    // })],

    state: () => {
        return {
            dbMode: true,

            languageDataLoading: false,

            // The selected languages [scriptIso], none to start with
            selectedLanguages: [],
            highlightedLanguages: [],
            highlightSingleCharacter: false,

            // search & filter options
            search: "",
            searchResults: [],
            searchMessage: "",
            searchSuccess: false,
            searchLoading: false,

            // Left panel settings
            includeSecondaryAndDeprecated: false,
            includeHistoricalAndConstructed: false,
            includeDraft: false,
            includeVerified: true,
            showDetails: false,

            // Right panel Font checker settings
            showSupported: true,
            showUnsupported: true,
        }
    },

    actions: {
        /**
         * Add the list of given scriptIsos to the currently selected Languages
         * @param {scriptIsos} payload 
         */
        addLanguages(payload) {
            console.debug("appStore, addLanguages", payload)
            this.selectedLanguages = Utils.arrayUnique([...this.selectedLanguages, ...payload])
        },
        
        setLanguage(payload) {
            console.debug("appStore, setLanguage", payload)
            this.selectedLanguages = [payload]
        },
        
        removeLanguages(payload) {
            console.debug("appStore, removeLanguages", payload)
            this.selectedLanguages = this.selectedLanguages.filter((i) => {
                return payload.indexOf(i) === -1
            })
        },

        removeLanguagesByIso(payload) {
            console.debug("appStore, removeLanguagesByIso", payload)
            this.selectedLanguages = this.selectedLanguages.filter((i) => {
                var iso = i.split("_")[1];
                return payload.indexOf(iso) === -1
            })
        },

        filterLanguages(supportedScriptIsos) {
            console.debug("appStore, filterLanguages", supportedScriptIsos)
            supportedScriptIsos = Helpers.languagesToList(supportedScriptIsos)
            this.selectedLanguages = this.selectedLanguages.filter(scriptIso => {
                // TODO maybe output notice about removed languages?
                return supportedScriptIsos.indexOf(scriptIso) !== -1
            })
        },

        hasSeenTutorial() {
            return false
        },
    }
})