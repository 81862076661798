/**
 * Helper that will query the API and locally cache all responses.
 * 
 * TODO implement storing this in localStorage for even better performance on
 * subsequent calls
 * TODO re-implement throttling calls
 */

var cache = {}

function forChars(chars) {

    let missing = [],
        pending = [];

    if (chars.length === 0) {
        return
    }

    chars.forEach((char) => {
        if (char in cache) {
            // If the char is already in the cache, resolve it to the value in
            // the cache (which might be a async Promise resolving from a
            // different request!)
            pending.push(Promise.resolve(cache[char]))
        } else {
            missing.push(char)
        }        
    })

    if (missing.length !== 0) {

        console.debug("fetch all missing:", missing)

        let req = fetch(process.env.VUE_APP_API_URI + "unicodedata", {
                method: "POST",
                body: "c=" + missing.join("&c="),
                headers: {
                    "Content-type": "application/x-www-form-urlencoded",
                },
            }).then((res) => res.json())
            .then(function (data) {
                // For the ordered API response add the actual character as
                // last element so in the promise resolver we can match them
                // to the requested chars
                for (let m of missing) {
                    data[m].push(m)
                }
                return data
            })

        // For all missing chars create a new individual Promise that we
        // resolve when the request to the API resolves, returning the API
        // response info for that char
        missing.forEach((char) => {
            cache[char] = new Promise((resolve) => {

                // Then the request resolves, resolve this Promise with the
                // char's info
                req.then((data) => {
                    resolve(data[char])
                })
            })
            pending.push(cache[char])
        })

        pending.push(req)
    }

    // Return a promise that, when resolving, resolves each requested char
    // If none are missing what gets returned is the resolved Promises from the
    // cache
    return Promise.all(pending)
}


export default {
    forChars
}