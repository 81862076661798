<template>
    <div id="scripts">

        <p class="warning" v-if="!includeVerified && !includeDraft">Select at least verified or unverified
            languages, or both, in the filter options above.</p>

        <div v-for="scriptobj in scripts" :key="scriptobj">
            <div v-for="(languages, s, index) in scriptobj" :key="index">
                <Languages :languages="languages" :script="s" />
            </div>
        </div>

        <h3 class="database-count">
            <span v-if="hasPreviewFont">
                <span>Supported languages in font: <span class="tooltip-link"
                        v-tooltip="$texts.tooltip('tooltip_language_count', numLanguages)">{{ numLanguages }}</span></span><br>
                <span>Total speakers:
                    <span class="speakers-count tooltip-link"
                        v-tooltip="$texts.tooltip('tooltip_speaker_count')">{{ numSpeakers }}</span>
                </span>
            </span>

            <span v-else>
                <span>Total languages in database: <span class="tooltip-link"
                        v-tooltip="$texts.tooltip('tooltip_language_count', numLanguages)">{{ numLanguages }}</span></span><br>
                <span>Total speakers:
                    <span class="speakers-count tooltip-link"
                        v-tooltip="$texts.tooltip('tooltip_speaker_count')">{{ numSpeakers }}</span>
                </span>
            </span>
        </h3>

        <div class="loading-cover"
            :class="{ 'loading': previewFontLoading || (searchLoading && search.trim().length === 1) }">
            <div class="loading-spinner"></div>
            <span class="loading-message">{{ $texts.t.loading }}</span>
        </div>
    </div>
</template>


<script>
import Languages from "./Languages"
import Hyperglot from "../hyperglot.json"
import { useAppStore } from "../stores/appStore"
import { useFontStore } from "../stores/fontStore"
import { mapState } from "pinia"

export default {
    name: "Scripts",

    inheritAttrs: false,

    components: {
        Languages,
    },

    mounted: function () {
        try {
            document.getElementById("left").scrollTop = 0
        } catch {
            // pass
        }
    },

    computed: {
        ...mapState(useAppStore, ['dbMode',
            'search',
            'searchLoading',
            'includeSecondaryAndDeprecated',
            'includeVerified',
            'includeDraft',
        ]),

        ...mapState(useFontStore, ['supported',
            'selectedLanguages',
            'chars',
            'previewFontLoading',
            'hasPreviewFont'
        ]),

        scripts: function () {
            let sortedScripts = []

            if (this.dbMode || (this.dbMode && this.supported.length === 0)) {
                for (let script in Hyperglot) {
                    let s = {};
                    s[script] = Hyperglot[script]

                    sortedScripts.push(s)
                }
            } else {
                // When we have a font selection fold all unsupported scripts (done in
                // <Languages>) and sort them at the end (done here);
                // The actual hiding/showing of supported/unsupported/filtered is done
                // in <Languages>
                let unsupported = {};

                for (let script in Hyperglot) {
                    if (this.supportedScripts.indexOf(script) !== -1) {
                        let s = {};
                        s[script] = Hyperglot[script]
                        sortedScripts.push(s)
                    } else {
                        unsupported[script] = Hyperglot[script]
                    }
                }

                for (let appendScript in unsupported) {
                    let s = {};
                    s[appendScript] = Hyperglot[appendScript]
                    sortedScripts.push(s)
                }
            }
            return sortedScripts
        },

        // Transform this.supported from [scriptIso] array to {script: [iso]} object
        supportedScripts: function () {
            if (typeof(this.supported) === "undefined" || this.supported.length === 0) {
                return []
            }
            return Object.keys(this.supported)
        },

        numLanguages: function () {
            let languages = [];

            if (!this.hasPreviewFont) {
                languages = this.$helpers.languagesToList(Hyperglot)

                // Count only iso, not script + iso (since that would give duplicates)
                languages = languages.map((scriptIso) => {
                    return scriptIso.split("_")[1]
                });
            } else {
                for (let script in this.supported) {
                    languages = languages.concat(this.supported[script]);
                }
            }
            return this.$utils.arrayUnique(languages).length;
        },

        numSpeakers: function () {
            let speakers = 0,
                counted = [];

            // Speaker counts are per language, not per orthography/script, so count
            // per ISO, not per scriptIso

            if (!this.hasPreviewFont) {
                // The full DB data indexed by script & iso
                for (let script in Hyperglot) {
                    for (let iso in Hyperglot[script]) {
                        if (counted.indexOf(iso) === -1) {
                            speakers += Hyperglot[script][iso].s
                            counted.push(iso)
                        }
                    }
                }
            } else {
                // The supported languages, indexed by script and as array of isos
                for (let script in this.supported) {
                    for (let iso of this.supported[script]) {
                        if (counted.indexOf(iso) === -1) {
                            // Even though we iterate this.supported, access the language
                            // data from Hyperglot, since this.supported only has the iso
                            // keys without data
                            try {
                                speakers += Hyperglot[script][iso].s
                                counted.push(iso)
                            } catch (e) {
                                console.error(e)
                                console.warn(script, iso)
                            }
                        }
                    }
                }
            }

            return this.$helpers.formattedSpeakers(speakers);
        },
    },
};
</script>

<style lang="scss" scoped>
.tooltip-wrapper {
    position: relative;
    top: -0.125em;
}

.loading-cover {
    left: -$gutter;
    right: -$gutter;
    background: var(--background-dimmed);

    .loading-message,
    .loading-spinner {
        top: 15rem;
    }
}

.database-count {
    @extend .italic;
    margin-top: $gutter*1;
}
</style>