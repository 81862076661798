/**
 * API Cache abstraction for fetching Hyperglot language data
 */
import { defineStore } from "pinia"

export const useLanguageStore = defineStore('languageData', {
    // Persist the app state store in the browser windows (between reloads) but 
    // clear on window close
    // plugins: [createPersistedState({
    //   storage: window.sessionStorage,
    // })],

    state: () => {
        return {
            // The selected languages, none to start with
            languageData: {},
            
            languageDataLoading: false,
        }
    },

    actions: {
        async fetchLanguageData(isos) {
            console.warn("fetchLanguageData for", isos)

            const cached = window.localStorage.getItem("languageData")
            if (cached) {
                this.languageData = JSON.parse(cached)
            }

            this.languageDataLoading = true

            let missing = isos.filter(iso => Object.keys(this.languageData).indexOf(iso) === -1)

            if (!missing.length) {
                this.languageDataLoading = false
                return this.languageData
            }

            let res = await fetch(process.env.VUE_APP_API_URI + "languages", {
                method: "POST",
                body: "i=" + isos.join("&i="),
                headers: {
                    "Content-type": "application/x-www-form-urlencoded",
                },
            }),
                json = await res.json()

            for (let iso in json) {
                this.languageData[iso] = json[iso];
            }

            this.languageDataLoading = false

            window.localStorage.setItem("languageData", JSON.stringify(this.languageData))

            return json
        }
    }
})