/**
 * General utilities for wielding javascript like string/array/object functions
 */
export default {

    trackEvent: function (name, category, label, value) {
        console.debug("trackEvent", name, category, label, value)
        try {
            window.plausible(name, {
                props: {
                    event_category: category,
                    event_label: label,
                    value: value,
                },
            });
        } catch (e) {
            console.error(e);
        }
    }
    
}