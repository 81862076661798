<template>
    <div>
        <ul class="charset notranslate" :lang="lang">
            <li v-for="char in sortedChars" :key="char"
                :class="{ 'filtered': (isCharacterSupported(char) && !showSupported || !isCharacterSupported(char) && !showUnsupported) }">

                <!-- isNaN check here because the reactive object/keys somehow returns indexes at some point before recomputing -->
                <Character v-if="isNaN(char)" :char="char" :alternate="Object.keys(alternates).indexOf(char) !== -1"
                    :alternateScriptIsos="Object.keys(alternates).indexOf(char) !== -1 ? alternates[char] : []"
                    :scriptIsos="chars[char]" :aux="aux" :mark="mark" :unicodedata="unicodedata[char]"
                    :supported="isCharacterSupported(char)" />
            </li>
        </ul>
    </div>
</template>

<script>
import UnicodeData from "../UnicodeData"
import Character from "./Character"

import { useAppStore } from "../stores/appStore"
import { useFontStore } from "../stores/fontStore"
import { mapState, mapActions } from "pinia"

export default {
    name: "CharacterList",

    components: {
        Character,
    },

    data: function () {
        return {
            unicodedata: {}
        }
    },

    props: [
        "chars",
        "aux",
        "mark",
        "displayFontLoaded",
        "alternates", // A dict with char keys and [scriptIsos] having that alternate
        "lang",
    ],

    created: function () {
        this.fetchChars()
    },

    watch: {
        chars: function () {
            this.fetchChars()
        }
    },

    computed: {

        ...mapState(useAppStore, [
            'showSupported',
            'showUnsupported',
        ]),

        ...mapState(useFontStore, [
            'hasPreviewFont'
        ]),

        sortedChars: function () {

            // Sort chars by their unicode, implicitly
            // for (let char of 
            let sorted = Object.keys(this.chars).sort((a, b) => {

                // Keep Uppercase first, if there is case in the script
                try {                
                    const a_is_uppercase = /\p{Lu}/u.test(a),
                        b_is_uppercase = /\p{Lu}/u.test(b);
                    
                    // Both directions are needed, browsers seem to sort in
                    // different orders/algorithms here
                    if (!a_is_uppercase && b_is_uppercase) {
                        return 1;
                    }
                    if (a_is_uppercase && !b_is_uppercase) {
                        return -1;
                    }
                } catch (e) {
                    console.warn(e)
                }

                // For anything containing ZWJ remove those so those positional
                // forms sort next to their base forms
                // Note that plain ZWJ by itself can be part of a charset and
                // should get sorted, so if the trimmed single character is
                // ZWJ itself, don't remove ZWJ before sorting, obviously
                const a_plain = a.trim() === "\u200D" ? "\u200D" : a.replace(/\u200D/g, ""),
                    b_plain = b.trim() === "\u200D" ? "\u200D" : b.replace(/\u200D/g, "");

                // For e.g. positional forms of the same base unicode, sort
                // those with ZWJ to the back, meaning to follow their base
                // character in the sequence
                // If the non-ZWJ forms are similar, we're likely comparing
                // between base and positional forms
                if (a_plain === b_plain) {
                    let a_split = a.split(/\u200D/g),
                        b_split = b.split(/\u200D/g);
                    
                    // If the length split characters are both longer than one 
                    // we are comparing positional forms amongst each other,
                    // so sort them fina > medi > init 
                    if (a_split.length > 1 && b_split.length > 1) {
                        
                        let a_is_init = a_split[0] !== "" && a_split.length == 2,
                            // a_is_medi = a_split.length == 3,
                            a_is_fina = a_split[a_split.length - 1] !== "" && a_split.length == 2,
                            // b_is_init = b_split[0] !== "" && b_split.length == 2,
                            b_is_medi = b_split.length == 3,
                            b_is_fina = b_split[b_split.length - 1] !== "" && b_split.length == 2;

                        let a_before_b = false;

                        if (b_is_fina && !a_is_fina ||
                            b_is_medi && a_is_init) {
                            a_before_b = true
                        }
                        
                        // Sort positional forms into order
                        return a_before_b ? 1 : -1;

                    } else if (a_split.length > 1 && b_split.length === 1) {
                        // Positional forms after base forms with length 1
                        return 1
                    } else if (a_split.length === 1 && b_split.length > 1) {
                        // Base form first
                        return -1
                    }
                }

                return a_plain > b_plain ? 1 : -1
            })

            return sorted
        }
    },

    methods: {
        ...mapActions(useFontStore, ['isCharacterSupported']),

        /**
         * On create or chars change trigger an async fetch for the chars and
         * pass their unicode data into the actual char objects.
         * This way we can consolidate the character data API call of the
         * entire list as one call.
         */
        fetchChars: function () {
            const that = this;

            if (Object.keys(this.chars).length !== 0) {
                UnicodeData.forChars(Object.keys(this.chars)).then((info) => {
                    // The response is an array of unicode data with the 4th 
                    // element being the actual char, so sort the data into
                    // an object indexed by chars
                    for (let i of info) {
                        that.unicodedata[i[3]] = i
                    }
                });
            }
        },
    }
}
</script>

<style lang="scss" scoped>
li {
    &.filtered {
        display: none;
    }
}
</style>