/**
 * Parse a CSS Unicode range into an array of unicodes
 * 
 * TODO wildcard range parsing implementation
 * 
 * <unicode-range> values 
 * unicode-range: U+26;               /* single codepoint 
 * unicode-range: U+0-7F;
 * unicode-range: U+0025-00FF;        /* codepoint range 
 * unicode-range: U+4??;              /* wildcard range 
 * unicode-range: U+0025-00FF, U+4??; /* multiple values 
 */

export default class {

    constructor(rangeString) {

        if (!rangeString) {
            this.unicodes = []
            return this.unicodes
        }

        this.unicodes = []

        let parts = rangeString.split(","),
            re = /(?:U|u){1}\+{1}([a-fA-F0-9]{1,4})-?([a-fA-F0-9]{0,4})/mi;

        for (let p = 0; p < parts.length; p++) {
            let part = parts[p],
                matches = part.match(re)

            // matches[0] is always the full match, [1] the first hex, [2] "" 
            // or the second hex
            if (matches.length === 3) {
                if (matches[2] === "") {
                    this.unicodes.push(parseInt(matches[1], 16))

                } else {
                    let start = parseInt(matches[1], 16),
                        end = parseInt(matches[2], 16)

                    for (var u = start; u < end; u++) {
                        this.unicodes.push(u)
                    }
                }
            }
        }

        return this.unicodes
    }

}