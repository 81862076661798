<template>
    <main id="index" :class="{
        loading: (this.loading || this.previewFontLoading),
        'white-mode': this.dbMode,
        'blue-mode': !this.dbMode
    }" :style="{
    overflow: selectedLanguages.length === 0 ? 'hidden' : 'initial',
}">
        <div id="left">
            <h1 class="page-header" id="title"><router-link to="/">Hyperglot</router-link></h1>
            <header id="options">
                <ModeSwitcher></ModeSwitcher>

                <div v-if="this.dbMode" class="option-rows">
                    <Filters />
                </div>

                <div v-else class="option-rows">
                    <FontSelector />
                    <Filters />
                </div>

            </header>

            <Scripts />

            <Imprint class="show-on-mobile" />
        </div>

        <div id="right" ref="right">
            <div class="page-header">
                <h1 id="mobile-title" class="show-on-mobile"><router-link to="/">Hyperglot</router-link></h1>
                <a id="credit" href="https://www.rosettatype.com">
                    <span class="hide-on-desktop-small">A project by Rosetta</span>
                    <span class="show-on-desktop-small">By Rosetta</span>
                </a>
            </div>
            <router-view v-slot="{ Component, route }">
                <transition :name="route.meta.transition || 'fade'">
                    <component :is="Component" />
                </transition>
            </router-view>
        </div>

        <div class="loading-cover loading" v-if="loading">
            <div class="loading-spinner-large"></div>
            <span class="loading-message">{{ $texts.t.loading }}</span>
        </div>

        <Modal ref="intro" id="intro">
            <h1 class="italic bold font-size-l">Hyperglot 2 is here! What’s new?</h1>
            <ul class="bullets font-size-s">
                <li>Study languages in full detail in the Database view</li>
                <li>Link directly to language entries in the Database view</li>
                <li>Analyse your fonts’ potential language support in Font checker</li>
                <li>Aggregate character sets for multiple languages with Font checker</li>
                <li>View languages that are not fully supported by your font to see what characters it is missing</li>
                <li>View and assess reliability of the data</li>
                <li>View additional design requirements</li>
                <li>Copy language data in different formats</li>
                <li>Search for language names, autonyms, or individual characters</li>
            </ul>
            <button class="button-filled" v-on:click="$refs.intro.close()">Get started</button>
        </Modal>

        <notifications group="messages" position="top center" />
    </main>
</template>

<script>
import Modal from "../components/Modal"
import ModeSwitcher from "../components/ModeSwitcher"
import Filters from "../components/Filters"
import Imprint from "../components/Imprint"
import FontSelector from "../components/FontSelector"
import Scripts from "../components/Scripts"
import Hyperglot from "../hyperglot.json"
import { useFontStore } from "../stores/fontStore"
import { useAppStore } from "../stores/appStore"
import { mapState } from "pinia"

export default {
    name: "Index",

    components: {
        ModeSwitcher,
        Modal,
        Filters,
        FontSelector,
        Scripts,
        Imprint,
    },

    data: function () {
        var allLanguages = this.$helpers.languagesToList(Hyperglot);

        return {
            // On initial load show all languages as supported (no font is selected)
            supported: allLanguages,
            loading: false,
            showIntro: true,
        };
    },

    computed: {
        ...mapState(useAppStore, ['selectedLanguages', 'dbMode']),

        ...mapState(useFontStore, ['previewFontLoading']),

        listed: function () {
            let supported = []

            return supported
        },
    },

    created() {
        // Verify the API is up
        console.log("API URI", process.env.VUE_APP_API_URI);
        fetch(process.env.VUE_APP_API_URI)
            .then((res) => res.text())
            .then((data) => console.log("API", data))
            .catch((error) => {
                console.error("API error", error);

                this.$notify({
                    group: "messages",
                    type: "error",
                    title: "Server not reachable.",
                    text:
                        "The server for processing instance request appears to be unreachable. Please try again in a moment.",
                    duration: -1,
                });
            });
    },

    mounted: function () {
        if (this.showIntro && !this.$root.hasSeenIntro && !this.$root.hasExitedIntro) {
            this.$refs.intro.open()
        }
    },

    watch: {
        supported: function () {
            this.loading = false;
        },

        '$root.openIntro': function (val) {
            if (val === true) {
                this.$refs.intro.open()
                this.$root.openIntro = false
            }
        },

        'previewFontLoading': function (is_loading) {
            if (is_loading) {
                try {
                    document.getElementById("left").scrollTop = 0
                    document.getElementById("right").scrollTop = 0
                } catch (e) {
                    console.error(e)
                }
            }
        }
    },
};
</script>

<style lang="scss">
// Prevent scrolling when waiting for font load or other data
#index.loading {
    #left,
    #right {
        cursor: wait;
        overflow: hidden;
    }
}

#options {
    @include gutter(1, 0, "margin");
    margin-top: 6px;

    .option-rows>* {
        @include gutter(0.25, 0, "margin");
        margin-bottom: 0 !important;
    }
}

@include mobile {
    #options {
        @include gutter(1, 0, "margin");
    }
}

#intro {
    ul {
        margin-top: 1em;
        margin-bottom: 1em;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.25s ease-out, transform 0.5s ease-out;
    transform: translateX(0);
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
    transform: translateX(-5%);
}

.fade-enter-active {
    display: none !important;
}
</style>
 