/**
 * Helpers specific to this domain.
 * 
 * These are available with Helpers.methodname or also mounted globally on the
 * app like so: {{ $helpers.methodname() }} or this.$helpers.methodname()
 */

import Utils from "./Utils"
import Hyperglot from "./hyperglot.json"

/**
 * Nasty hackses!
 * Via https://stackoverflow.com/a/37729608/999162
 */
String.prototype.charCodeUTF32 = function () {
    let first = this.charCodeAt(0),
        second = this.charCodeAt(1)

    if (second) {
        return ((first - 0xD800) * 0x400) + (second - 0xDC00) + 0x10000;
    }
    return first
};

function unicodeShortId(char) {
    // For unencoded base + mark combinations return the compound

    // Note that the unencoded base + mark A̧ will have length 2, but in javascript
    // also single UTF16 glyph 𑄇 will have length 2!!!
    // The charCodeUFT32() will return a negative for true 2-character combos
    if (char.length > 1 && char.charCodeUTF32() < 0) {
        try {
            let all = [];
            for (let i = 0; i < char.length; i++) {
                // We implicitly assume this will be 16bit unicodes, else all
                // things will go mad; in which case return blank
                all.push(uniHex(char.charCodeAt(i).toString(16)))
            }
            return all.join(" + ")
        } catch (e) {
            console.error(e)
            return ""
        }
    }

    const hex = (char.charCodeUTF32()).toString(16)

    return uniHex(hex)
}

function uniHex(hex) {
    try {
        return "U+" + Array(4 - hex.length + 1).join("0") + hex.toUpperCase()
    } catch (e) {
        console.warn(e)
        return "U+" + hex
    }
}

export default {
    familyNameFromFileName: function (path) {
        return path.replace(/\/|\./g, "_");
    },

    /**
     * Transform:
     * ["Latin_deu", "Arabic_aar"]
     * to:
     * { Latin: ["deu"], "Arabic: ["aar"] }
     * 
     * @param script_prefixed_isos 
     */
    languagesToDict: function (input) {
        if (typeof (input) === "undefined") {
            return {}
        }
        let scripts = {}
        for (var l = 0; l < input.length; l++) {
            let parts = input[l].split("_")

            if (Object.keys(scripts).indexOf(parts[0]) === -1) {
                scripts[parts[0]] = []
            }
            scripts[parts[0]].push(parts[1])
        }

        return scripts
    },

    /**
     * Get the language name from hyperglot.json by scriptIso or iso
     * 
     * @param {*} key: iso or scriptIso of the language
     * @returns str
     */
    languageName: function (key) {
        let iso = key;
        if (key.indexOf("_") !== -1) {
            iso = key.split("_")[1]
        }

        for (let script in Hyperglot) {
            for (let _iso in Hyperglot[script]) {
                if (_iso === iso) {
                    return Hyperglot[script][iso].n
                }
            }
        }
        return ""
    },

    /**
     * Transform:
     * { Latin: ["deu"], "Arabic: ["aar"] }
     * { Latin: {"deu": ...}, "Arabic: {"aar": ...} }
     * to:
     * ["Latin_deu", "Arabic_aar"]
     * 
     * @param input 
     */
    languagesToList: function (input) {
        if (typeof (input) === "undefined") {
            return {}
        }
        let langs = []
        for (let script in input) {
            let isos = []
            if (Array.isArray(input[script])) {
                isos = input[script]
            } else {
                isos = Object.keys(input[script])
            }
            for (let i = 0; i < isos.length; i++) {
                langs.push(script + "_" + isos[i])
            }
        }
        return langs
    },

    formattedSpeakers: function (speakers, abbreviated) {
        /*
                    123         123
                  3 456       3.456
                123 456     123.456
              3 456 789           3 M
            123 456 789         123 M
          9 123 456 789           9.1 B
         89 123 456 789          89.1 B
        798 123 456 789         789.1 B
        */

        if (typeof (abbreviated) === "undefined") {
            abbreviated = false
        }

        if (speakers > 1000000000) {
            // let b_one_deci = Math.round(speakers / 100000000).toString();
            // b_one_deci = b_one_deci.slice(0, 1) + "." + b_one_deci.slice(1);
            // speakers = b_one_deci + "B";
            let billions_one_deci = speakers / 1000000000
            // console.log(billions_one_deci, billions_one_deci.toString())
            speakers = Math.round(billions_one_deci * 10) / 10 + "B"
        } else if (speakers > 1000000) {
            speakers =
                Utils.numberWithSeparator(Math.round(speakers / 1000000)) + "M";
        } else {
            speakers = Utils.numberWithSeparator(speakers);
        }

        if (!abbreviated) {
            speakers = speakers.replace("B", " billion").replace("M", " million")
        }

        return speakers;
    },

    /**
     * Gets a U+0000 formatted str for any character (including UFT-32 and base + mark)
     */
    unicodeShortId: unicodeShortId,

    /**
     * Get Unicodepoints of a list of characters, some of which may be
     * base + mark components
     */
    codepoints: function (arr) {
        let unicodes = []
        for (let i = 0; i < arr.length; i++) {
            let str = arr[i]
            if (str.length == 1) {
                unicodes.push(str)
            } else if (str.length > 1) {
                for (const char of str) {
                    unicodes.push(char)
                }
            }
        }
        return Utils.arrayUnique(unicodes)
    },

    /**
     * 
     * @param {*} scriptIso e.g. Arabic_bal
     * @returns {str} iso e.g. bal
     */
    isoWithoutScript: function (scriptIso) {
        return scriptIso.split("_")[1]
    },

    /**
     * Return the singular input as plural if count !== 1
     * 
     * @param {*} singular 
     * @param {*} count 
     * @returns 
     */
    pluralized: function (singular, count) {
        const mapping = {
            // Not needed for simple +s plurals, but e.g.:

            // "language": "languages", < NOT NEEDED, simple +s
            // "circus": "circuses" < NEEDED entry
            // "radius": "radi" < NEEDED entry
        }

        if (isNaN(count)) {
            console.warn("Helpers.pluralized called without numeric count", count)
            return singular
        }

        // If an explicit pluralization of the word exists return it
        if (singular in mapping) {
            return count === 1 ? singular : mapping[singular]
        }

        // English easy mode, append s
        return count === 1 ? singular : singular + "s"
    },

}