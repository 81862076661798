<template>
    <div>
        <div v-if="selectedLanguages.length === 0" class="hide-on-mobile">
            <section id="selected-languages">
                <h3>Selected languages</h3>
                <p>← drop a font and select a language in the left panel</p>
            </section>

            <section class="dimmed">
                <h3>Aggregated base characters</h3>
                <CharacterListPlaceholder :count="27" />
            </section>

            <section class="dimmed">
                <h3>Aggregated auxiliary characters</h3>
                <CharacterListPlaceholder :count="5" />
            </section>
        </div>

        <div v-else id="preview">
            <section id="preview-header">
                <div class="header-buttons">
                    <button id="filters-charset-toggle" class="text-button" v-on:click="toggleFilters"><i data-v-61dd607b=""
                            class="icon-settings"></i></button>
                    <CopyToClipboard class="copy-wrapper" :base="copyBase" :aux="copyAux" :mark="copyMark"
                        :show_option_language_names="true" :show_option_characters="true">
                    </CopyToClipboard>
                </div>
                <div id="filters-charset" :class="{ 'collapsed': filtersCollapsed }">
                    <label class="checkbox heavy" :class="{ 'disabled': !hasPreviewFont }">
                        <input v-model="showSupported" type="checkbox" class="switch-input" />
                        <span>{{ $texts.t.filter_show_supported }}</span>
                    </label>
                    <label class="checkbox heavy" :class="{ 'disabled': !hasPreviewFont }">
                        <input v-model="showUnsupported" type="checkbox" class="switch-input" />
                        <span>{{ $texts.t.filter_show_unsupported }}</span>
                    </label>
                    <small v-if="!hasPreviewFont" class="ui-feedback">{{ $texts.t.notice_supported_options }}</small>
                </div>
            </section>

            <section id="selected-languages" class="expandable" :class="{ 'expanded': showSelectedLanguages }"
                v-if="languageNames.length">

                <header>
                    <h3>Selected {{ numLanguages }} {{ $helpers.pluralized("language", numLanguages) }} ({{ numSpeakers
                    }} speakers)</h3>
                </header>

                <ul id="language-list" class="comma-list"
                    :class="{ 'capped': languageNames.length > 12 && capLanguageList }">
                    <li v-for="(lang, index) in languageNames" :key="index"
                        v-on:mouseenter="highlightedLanguages = [lang[1]]" v-on:mouseleave="highlightedLanguages = []"
                        :class="{ 'highlighted': highlightedLanguages.indexOf(lang[1]) !== -1 }">
                        <span>{{ lang[0] }}</span>
                    </li>
                    <li v-if="languageNames.length > 12 && capLanguageList">
                        <span class="text-button" v-on:click="capLanguageList = false">Show all
                            {{ languageNames.length }} languages</span>
                    </li>
                </ul>

                <button id="clear-selection" class="text-button" v-on:click="selectedLanguages = []">Clear
                    selection</button>
            </section>

            <section id="base-characters" v-if="Object.keys(base).length !== 0 || languageDataLoading">
                <h3>Aggregated base characters</h3>
                <CharacterList :chars="base" :alternates="alternates" :aux="false" :mark="false" :lang="''">
                </CharacterList>
                <small v-if="hasPreviewFont && (!showSupported && !showUnsupported)" class="ui-feedback">{{ $texts.t.notice_no_characters_visible }}</small>
            </section>

            <section id="aux-characters" v-if="Object.keys(aux).length !== 0">
                <h3>Aggregated auxiliary characters</h3>
                <CharacterList :chars="aux" :alternates="alternates" :aux="true" :mark="false" :lang="''">
                </CharacterList>
                <small v-if="hasPreviewFont && (!showSupported && !showUnsupported)" class="ui-feedback">{{ $texts.t.notice_no_characters_visible }}</small>
            </section>

            <section id="mark-characters" v-if="Object.keys(mark).length !== 0">
                <h3>Aggregated mark characters</h3>
                <CharacterList :chars="mark" :alternates="alternates" :aux="false" :mark="true" :lang="''">
                </CharacterList>
                <small v-if="hasPreviewFont && (!showSupported && !showUnsupported)" class="ui-feedback">{{ $texts.t.notice_no_characters_visible }}</small>
            </section>

            <section class="design-requirements-section" v-if="Object.keys(designRequirements).length !== 0"
                v-on:click="show_designRequirements = !show_designRequirements">
                <h3>Design requirements</h3>
                <div v-for="(requirements, languageScriptIsoStringList) in designRequirements"
                    :key="languageScriptIsoStringList">
                    <div class="design-requirements-wrapper" :data-language-names="languageScriptIsoStringList">
                        <h4>{{ languageNameList(languageScriptIsoStringList.split(",")).join(", ") }}</h4>
                        <ul class="design-requirements" :class="{ 'no-numbering': requirements.length === 1 }">
                            <li v-for="(req, index) in requirements" :key="index">
                                {{ req }}
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>

        <footer id="page-footer" class="hide-on-mobile" :class="{ 'dimmed': selectedLanguages.length === 0 }">
            <Imprint />
        </footer>

        <div class="loading-cover loading" v-if="languageDataLoading">
            <div class="loading-spinner-large"></div>
            <span class="loading-message">{{ $texts.t.loading }}</span>
        </div>

        <Modal ref="cta" id="cta">
            <p class="italic bold">To start, drop a font and select a language in the left panel</p>
        </Modal>
    </div>
</template>


<script>
import Hyperglot from "../hyperglot.json"
import FontFaceObserver from "../../node_modules/fontfaceobserver"

import Modal from "./Modal"
import Imprint from "./Imprint"
import CopyToClipboard from "./CopyToClipboard"
import CharacterList from "./CharacterList"
import CharacterListPlaceholder from "./CharacterListPlaceholder"

import { useAppStore } from "../stores/appStore"
import { useFontStore } from "../stores/fontStore"
import { useLanguageStore } from "../stores/languageStore"
import { mapState, mapActions, mapWritableState } from "pinia"

export default {
    name: "Preview",

    components: {
        CharacterList,
        CharacterListPlaceholder,
        Modal,
        Imprint,
        CopyToClipboard,
    },

    props: ["font"],

    data: function () {
        return {
            selectedLanguagesData: {},
            showSelectedLanguages: true,
            displayFontLoaded: false,
            languageDataPending: false,
            capLanguageList: true,
            showCta: true,
            filtersCollapsed: true,
        }
    },

    created: function () {
        let noto = new FontFaceObserver("Noto Sans"),
            that = this;

        noto.load().then(function () {
            that.displayFontLoaded = true;
        });
    },

    mounted: function () {
        const that = this;

        document.getElementById("right").scrollTop = 0

        if (!this.$root.hasSeenCta && this.showCta) {
            this.$refs.cta.open()
            this.showCta = false
            this.$root.hasSeenCta = true

            setTimeout(function () {
                try {
                    that.$refs.cta.close()
                } catch {
                    // pass
                }
            }, 2500)
        }
    },

    computed: {
        ...mapState(useFontStore, ['supported', 'hasPreviewFont']),

        ...mapState(useLanguageStore, ['languageData']),

        ...mapState(useAppStore, [
            'highlighted_char',
            'includeSecondaryAndDeprecated',
            'includeDraft',
        ]),

        ...mapWritableState(useAppStore, [
            'selectedLanguages',
            'highlightedLanguages',
            'showSupported',
            'showUnsupported',
        ]),

        ...mapState(useLanguageStore, ['languageDataLoading']),

        languageNames: function () {
            let langs = [];

            for (let scriptIso of this.selectedLanguages) {
                langs.push([this.$helpers.languageName(scriptIso), scriptIso])
            }

            return this.$utils.arrayUnique(langs).sort()
        },

        base: function () {
            return this._getChars("bas")
        },

        aux: function () {
            return this._getChars("aux")
        },

        mark: function () {
            return this._getChars("mar")
        },

        alternates: function () {
            let alts = {}
            for (let scriptIso in this.selectedLanguagesData) {
                for (let orthography of this.selectedLanguagesData[scriptIso]) {
                    if ("alt" in orthography) {
                        for (let char of orthography.alt) {
                            if (!(char in alts)) {
                                alts[char] = []
                            }
                            alts[char].push(scriptIso)
                        }
                    }
                }
            }
            return alts
        },

        /**
         * Return a list of design requirments. Combine them by languages that
         * share the same design requirement, from most shared requirement to
         * least frequent.
         * 
         * Returns an object with { "Language A, Language B": ["Requiement A", "Requirement B", …], …}
         */
        designRequirements: function () {
            let reqs = [],
                langs = [];

            // Akin to this._getChars, but the constructed object is different:
            // { language name: [requirement, requirement, …] }
            for (let scriptIso in this.selectedLanguagesData) {
                for (let orthography of this.selectedLanguagesData[scriptIso]) {

                    // Determine which orthography to use:
                    // Either first primary for this script, or if including secondary and 
                    // deprecated take the compound values for base, aux and marks.
                    if (!this.includeSecondaryAndDeprecated && orthography.sta !== "primary") {
                        console.debug("skip non-primary")
                        continue
                    }

                    if ("req" in orthography) {
                        if (orthography.req.length === 0) {
                            continue
                        }
                        // let parts = scriptIso.split("_"),
                        //     script = parts[0],
                        //     iso = parts[1];

                        // let languageName = Hyperglot[script][iso].n;

                        // From all requirements in this orthography sort them into reqs/langs
                        for (let requirement of orthography.req) {

                            if (reqs.length === 0) {
                                // No entries, just push it
                                reqs.push(requirement)
                                langs.push([scriptIso])
                            } else {
                                // Have entries, see if requirement already exists
                                let existing_found = false;
                                for (let i = 0; i < reqs.length; i++) {

                                    if (reqs[i] === requirement) {
                                        // Requirement already listed, add language to list
                                        langs[i].push(scriptIso)
                                        existing_found = true

                                        // Done, it won't occur again
                                        break
                                    }
                                }

                                if (!existing_found) {
                                    // Requirement not in the list yet
                                    reqs.push(requirement)
                                    langs.push([scriptIso])
                                }
                            }
                        }
                    }
                }
            }

            // Sort the languages and requirements in to lists grouped by
            // languages sharing the same requirements (most languages first)
            let sorted = {}

            while (langs.length) {
                let mostFrequent = 0;

                for (let k = 0; k < langs.length; k++) {
                    if (langs[k].length > langs[mostFrequent].length) {
                        mostFrequent = k;
                    }
                }

                // Save the joined language names as index and add the requirement
                const languages = langs[mostFrequent].sort().join(",")
                if (!(languages in sorted)) {
                    sorted[languages] = []
                }
                sorted[languages].push(reqs[mostFrequent])

                // Remove this found mostFrequent list of languages and their requirements
                reqs = reqs.filter(function (item) { return item !== reqs[mostFrequent] })
                langs = langs.filter(function (item) { return item !== langs[mostFrequent] })
            }

            return sorted
        },

        numSpeakers: function () {
            let speakers = 0
            for (let scriptIso of this.selectedLanguages) {
                const split = scriptIso.split("_"),
                    script = split[0],
                    iso = split[1];

                speakers += Hyperglot[script][iso].s
            }
            return this.$helpers.formattedSpeakers(speakers)
        },

        numLanguages: function () {
            return this.selectedLanguages.length
        },

        copyBase: function () {
            return this._getCopyChars(this.base)
        },

        copyAux: function () {
            return this._getCopyChars(this.aux)
        },

        copyMark: function () {
            return this._getCopyChars(this.mark)
        },

    },

    methods: {
        ...mapActions(useFontStore, ['isCharacterSupported']),

        ...mapActions(useLanguageStore, ['fetchLanguageData']),

        ...mapActions(useAppStore, ['filterLanguages']),

        /**
         * Get the compound base/auxiliary/mark attribute to show.
         * 
         * This filters orthographies by primary/includeSecondaryAndDeprecated and will return
         * an object with {character:[scriptIso]} mappings
         * 
         * @param {*} attr 
         * @return {obj} { character: [scriptIso, …] }
         */
        _getChars: function (attr) {
            let compound = {};

            for (let scriptIso in this.selectedLanguagesData) {

                for (let orthography of this.selectedLanguagesData[scriptIso]) {
                    // Determine which orthography to use:
                    // Either first primary for this script, or if including secondary and 
                    // deprecated take the compound values for base, aux and marks.

                    if (!this.includeSecondaryAndDeprecated && orthography.sta !== "primary") {
                        console.debug("skip non-primary")
                        continue
                    }

                    if (!(attr in orthography)) {
                        console.debug("skip orthography without", attr)
                        continue
                    }

                    for (let character of orthography[attr]) {
                        if (!(character in compound)) {
                            compound[character] = [scriptIso]
                        } else {
                            if (compound[character].indexOf(scriptIso) === -1) {
                                compound[character].push(scriptIso)
                            }
                            // Else it already is included and marked with this scriptIso
                        }
                    }
                }
            }

            return compound
        },

        /**
         * Get base/aux/marks filtered against the display filter so only
         * visible characters will selected for copying.
         * 
         * @param {*} chars 
         */
        _getCopyChars: function (chars) {
            const that = this;

            if (!chars) {
                return []
            }

            return Object.keys(chars).filter(function (char) {
                const supported = that.isCharacterSupported(char);

                return supported && that.showSupported || !supported && that.showUnsupported
            })
        },

        languageNameList: function (scriptIsos) {
            const that = this,
                names = scriptIsos.map(function (scriptIso) {
                    return that.$helpers.languageName(that.$helpers.isoWithoutScript(scriptIso))
                });

            return names.sort()
        },

        toggleFilters: function () {
            console.log("toggleFilters", this.filtersCollapsed)
            this.filtersCollapsed = !this.filtersCollapsed
        }
    },

    watch: {
        // "supported"(val/*, old*/) {
        //     this.filterLanguages(val)
        // },

        /**
         * When the selected languages change, trigger a new call to 
         * fetchLanguageData.
         */
        "selectedLanguages": {
            deep: true,
            immediate: true,
            async handler(val) {

                const isos = val.map(e => {
                    let parts = e.split("_")
                    return parts[1]
                })

                if (isos.length === 0) {
                    this.selectedLanguagesData = {}
                    return
                }

                // Trigger the API call, wait for it to resolve, then update
                // the local selectedLanguagesData with the now available
                // data, which rebuilds our computed base/aux etc.
                this.languageDataPending = true
                await this.fetchLanguageData(isos)
                this.languageDataPending = false

                this.selectedLanguagesData = {}

                for (let i = 0; i < this.selectedLanguages.length; i++) {

                    const scriptIso = this.selectedLanguages[i],
                        parts = scriptIso.split("_"),
                        script = parts[0],
                        iso = parts[1],
                        lang = this.languageData[iso];

                    if (!lang) {
                        // If one API resolved but in the meantime
                        // selectedLanguages has changed this lang's data may
                        // not yet have resolved; the lang will then update
                        // once that awaited API call resolves
                        continue
                    }

                    console.debug("selected language", scriptIso)

                    let orthographies = []
                    for (let o in lang.ort) {
                        if (lang.ort[o].scr === script) {
                            console.debug("found orthography for", scriptIso, lang.ort[o])
                            orthographies.push(lang.ort[o])
                        }
                    }
                    this.selectedLanguagesData[scriptIso] = orthographies
                }
            }
        },
    }
}
</script>

<style lang="scss" scoped>
h3 {
    margin-bottom: 0.25rem;
}

#preview-header {
    padding: 0;
}

#language-list {
    display: inline;
    margin-right: 1em;

    // Cut off
    &.capped {
        li:nth-child(n + 13) {
            display: none;
        }

        li:last-child {
            &:before {
                content: "… ";
            }

            display: inline;
        }
    }
}

#filters-charset-toggle {
    margin-right: $gutter*0.25;

    i {
        position: relative;
        top: -6px;
    }
}

#filters-charset {
    display: flex;
    flex-direction: column;
    margin-bottom: $gutter*0.5;

    &.collapsed {
        display: none;
    }

    >* {
        margin-bottom: $gutter*0.25;
    }
}

section {
    @include gutter(0.5, 0);

    &.inactive {
        cursor: default !important;
        pointer-events: none !important;

        header,
        .copy,
        .expandable-toggle:before {
            color: $disabled;
        }
    }
}

section header {
    display: flex;
    justify-content: space-between;

    strong {
        flex-grow: 2;
    }
}

#selected-languages {
    padding-top: 0;

    header {
        @include mobile {
            display: block;
        }
    }
}

#selected-languages ul li {
    transition: background 0.2s ease-out;

    &.highlighted span {
        background: var(--link-muted);
        cursor: zoom-in;

        margin: 0 -3px;
        padding: 3px 3px;

        &:hover,
        &:focus {
            background: var(--link);
            color: var(--background);
        }

        &:after {
            background: var(--background);
        }
    }
}

.design-requirements-section>div+div {
    margin-top: $gutter * 0.25;
}

.dimmed {
    opacity: 0.25;
}

.loading-cover {
    display: block;
    background: var(--background-transluscent);

    .loading-message,
    .loading-spinner-large {
        top: 40vh;
    }
}

#cta.modal {
    background: transparent;

    :deep(.modal-content) {
        @include modal-content-minimal;

        .modal-close {
            display: none !important;
        }
    }
}

.icon-settings {
    font-size: 24px;
}
</style>