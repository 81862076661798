<template>
    <div id="search">
        <div class="search-input">
            <input type="search" placeholder="Search languages and characters" v-model="search" />
            <button class="search-button" v-on:click="search = ''" :class="{ 'round-button': searchMessage.length > 0 }">
                <i v-if="search.trim().length === 0" class="icon-search"></i>
                <i v-else class="icon-checkmark-small-filled-x"></i>

            </button>
        </div>
        <small v-if="searchMessage.length > 0" class="search-message"
            :class="{ 'loading': searchLoading, 'success': searchSuccess }"><span v-html="searchMessage"></span><i></i></small>
        <small v-if="search.length > 0 && searchMessage.length === 0 && loading" class="search-message loading">Searching…
            <i></i></small>
    </div>
</template>

<script>
import Hyperglot from "../hyperglot.json"
import { useAppStore } from "../stores/appStore"
import { useFontStore } from "../stores/fontStore"
import { mapState, mapWritableState } from "pinia"
import "../../node_modules/clipboard-polyfill/overwrite-globals"

export default {
    name: "Search",

    data: function () {
        return {
            collapsed: true,
            options: {},
        };
    },

    computed: {
        ...mapState(useFontStore, ['supported', 'hasPreviewFont']),
        ...mapWritableState(useAppStore, ['search', 'searchLoading', 'searchResults', 'searchMessage', 'searchSuccess']),
    },

    watch: {
        search: async function (value) {
            console.log("search", value)
            const that = this,
                s = value.trim(),
                _s = s.toLowerCase();

            this.searchLoading = true
            this.searchResults = [];

            // Restore to default/inactive filter when empty search
            if (s.length === 0) {
                this.searchMessage = "";
                return;
            }

            for (let script in Hyperglot) {
                for (let iso in Hyperglot[script]) {

                    const lang = Hyperglot[script][iso],
                        _name = lang.n.toLowerCase(),
                        scriptIso = script + "_" + iso;
                    let _autonym = false;

                    // See if the input matches an ISO
                    if (s.length === 3) {
                        if (iso === _s) {
                            console.debug("search matched iso", iso)
                            this.searchResults.push(scriptIso)
                        }
                    }

                    // Check against name and autonyms if the length is two or
                    // more
                    if (s.length >= 2) {
                        // See if the input matches a language by name
                        if (_name.indexOf(_s) !== -1) {
                            console.debug("search matched name", _name);
                            this.searchResults.push(scriptIso);
                        }

                        // See if the input matches a language by autonym of
                        // any of the language's orthographies
                        if ("o" in lang) {
                            for (let o of lang.o) {
                                if ("a" in o) {
                                    _autonym = o.a.toLowerCase();
                                    if (_autonym && _autonym.indexOf(_s) !== -1) {
                                        console.debug("search matched autonym", _autonym);
                                        this.searchResults.push(scriptIso);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // Unique hits, e.g. when multiple orthographies return the same iso hit
            this.searchResults = this.$utils.arrayUnique(this.searchResults)

            // If the search length is exactly 1 find languages with
            // this character in their chars (from API)
            if (s.length === 1) {
                this.searchResults = []
                this.searchSuccess = false
                this.searchMessage = ""

                await fetch(process.env.VUE_APP_API_URI + "search", {
                    method: "POST",
                    body: "s=" + s, // Search case sensitive!
                    headers: {
                        "Content-type": "application/x-www-form-urlencoded",
                    },
                })
                    .then((res) => res.json())
                    .then(function (data) {
                        // When the response resolves, check we're still
                        // looking for only that one char.
                        // We're awaiting this response, but new key input
                        // triggers a new search!
                        if (that.search.trim() === s) {
                            that.searchResults = data
                        }
                    }).catch((err) => {
                        console.error(err)
                    })
            }

            this.searchLoading = false

            if (this.searchResults.length > 0) {

                this.searchSuccess = true

                // This search may resolve after the input already changed and resolved, so re-check
                if (this.search.trim().length === 1) {
                    // Results from character search
                    this.searchMessage = "Found " + this.searchResults.length + " matching languages for character <em>" + s + "</em> with the applied display filters."
                } else {
                    // Results from name/iso/autonym
                    this.searchMessage = "Found " + this.searchResults.length + " matching language " + this.$helpers.pluralized("name", this.searchResults.length) + " with the applied display filters."
                }

                console.log(
                    "filtered matches",
                    this.searchResults,
                    this.searchResults.length,
                    this.supported.length
                );
            } else {
                console.log("search no results");
                this.searchMessage = "No matching languages found."
                this.searchSuccess = false
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.search-input {
    min-width: 0;
    flex-grow: 2;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;

    $icon-width: $button-height - 4px;

    input {
        max-width: none;
        min-width: 0;
        width: 100%;
        flex-grow: 2;
        background: transparent;
    }

    .search-button {
        box-sizing: border-box;
        margin-left: -$icon-width - 2px;
        margin-right: 2px;
        margin-top: 2px;
        height: $icon-width;

        width: $icon-width;
        display: inline-block;
        border: 0;
        background: linear-gradient(90deg, transparent 0%, var(--background-dimmed) 5px);
        line-height: 1;

        cursor: pointer;

        i {
            position: relative;
            top: -0.05em;
            color: var(--link);
        }

        &:hover>span:after {
            display: inline;
        }

        i.icon-search {
            font-size: 15px;
        }

        i.icon-checkmark-small-filled-x {
            font-size: 20px;
        }

        &:hover,
        &:focus {
            color: $blue;
        }
    }

    &:hover {
        &:after {
            color: $blue;
        }
    }
}

.search-message {
    @extend .ui-feedback;
    line-height: 15px;
    min-height: 15px;
    display: block;

    &.loading i {
        @extend .loading-spinner-small;
        position: relative;
        top: 1px;
    }

    // Character highlight
    :deep(em) {
        background: var(--link);
        color: var(--background);
        font-style: inherit;
        font-weight: inherit;
        border-radius: 2px;
        padding: 2px 4px;
    }
}

button {
    &:after {
        display: none;
    }
}
</style>