import { defineStore } from "pinia"
// import Utils from "../Utils"

export const useFontStore = defineStore('fontState', {
    // Persist the app state store in the browser windows (between reloads) but 
    // clear on window close
    // plugins: [createPersistedState({
    //   storage: window.sessionStorage,
    // })],

    state: () => {
        return {
            family: "",
            supported: [],
            chars: [],
            hasPreviewFont: false,
            previewFontLoading: false
        }
    },

    actions: {
        setSupported(payload) {
            console.log("fontStore setSupported", payload)
            this.supported = payload
        },

        clearFont(purgeLocalStorage) {
            if (typeof(purgeLocalStorage) === "undefined") {
                purgeLocalStorage = false
            }

            this.family = ""
            this.supported = []
            this.chars = []
            this.hasPreviewFont = false
            this.previewFontLoading = false

            if (purgeLocalStorage) {
                window.localStorage.removeItem("localFont")
                window.localStorage.removeItem("localFontFamily")
                window.localStorage.removeItem("localFontInfo")
            }
        },

        isCharacterSupported(char) {
            
            if (!this.hasPreviewFont) {
                return true
            }

            for (let bit of char) {
                if (this.chars.indexOf(bit) === -1) {
                    return false
                }
            }

            return true
        },
    }
})